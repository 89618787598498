import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { orderService } from './orderService';

export const createAnOrder = createAsyncThunk(
    "/cart/create-order",
    async (orderDetail, thunkAPI) => {
        try{
            return await orderService.createOrder(orderDetail);
        } catch(error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
);
export const getOrders = createAsyncThunk(
    "/get-orders",
    async (thunkAPI) => {
        try{
            return await orderService.getUserOrders();
        } catch(error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
);


const orderState = {
    order:"",
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:"",
    
}

export const orderSlice = createSlice({
    name:"order",
    initialState:orderState,
    reducers:{},
    extraReducers:(builder)=>{
        builder
        .addCase(createAnOrder.pending, (state)=>{
            state.isLoading=true;
        }).addCase(createAnOrder.fulfilled, (state, action)=> {
            state.isError=false;
            state.isSuccess=true;
            state.isLoading=false;
            state.orderedProduct=action.payload;
            if (state.isSuccess === true) {
                toast.info("Order placed successfully")
            }
        }).addCase(createAnOrder.rejected, (state, action)=>{
            state.isError=true;
            state.isSuccess=false;
            state.isLoading=false;
            state.message=action.error;
            if (state.isError === true) {
                toast.error(action.error)
            }
        })

        .addCase(getOrders.pending, (state)=>{
            state.isLoading=true;
        }).addCase(getOrders.fulfilled, (state, action)=> {
            state.isError=false;
            state.isSuccess=true;
            state.isLoading=false;
            state.getOrderedProducts=action.payload;
           
        }).addCase(getOrders.rejected, (state, action)=>{
            state.isError=true;
            state.isSuccess=false;
            state.isLoading=false;
            state.message=action.error;
           
        })
   } 
});

export default orderSlice.reducer;