import React from 'react'
import Container from '../components/Container';
// import BreadCrumb from '../components/BreadCrumb';
// import Meta from '../components/Meta';

const WarrantyRefundPolicy = () => {
  return (
    <>
        {/* <Meta title={'Warranty & Refund Policy'}/>
        <BreadCrumb title="Warranty & Refund Policy"
        /> */}

<Container class1='policy-wrapper py-5 home-wrapper-2'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='policy'>
                          <h1 className='mb-3'>Warranty And Refund Policy</h1>
                          <p className='mt-2'>
                          At Silicon Savannah Technologies, we prioritize customer satisfaction and stand behind the quality of our products. We offer warranty coverage for both brand new and refurbished laptops to ensure peace of mind for our customers.</p>
                          <p className='mt-2'>
                          All brand new laptops purchased from Silicon Savannah Technologies come with a manufacturer's warranty lasting six (6) months from the date of purchase. This warranty covers defects in materials and workmanship under normal use. Similarly, refurbished laptops are covered by a manufacturer's warranty for three (3) months from the purchase date.
                          </p>
                          <p className='mt-2'>

                          However, certain conditions apply to warranty claims. Damages resulting from accidents, misuse, neglect, or unauthorized modifications are not covered. Attempts to repair the laptop by unauthorized personnel will also void the warranty. Additionally, software-related issues are not included in the warranty coverage.

                          If you're not entirely satisfied with your purchase, you have the option to return the laptop within ten (10) days of the original purchase date for an exchange, provided the laptop is in its original condition and packaging. Refunds will be issued  excluding shipping and handling charges.
                          </p>
                          <p className='mt-2'>

                          To initiate a warranty claim or return, simply contact our customer service team within the specified time frames. Proof of purchase is required for all warranty claims. Our team will guide you through the process, which may involve troubleshooting steps or returning the laptop for repair or replacement.
                          </p>

                          <p className='mt-2'>
                          Please note that Silicon Savannah Technologies reserves the right to refuse any warranty claims or returns that do not meet the outlined criteria. Any updates or modifications to this policy will be communicated on our website.

                          If you have any questions or concerns regarding our Warranty and Refund Policy, please feel free to reach out to us. Thank you for choosing Silicon Savannah Technologies for your laptop and computer needs!
                          </p>
                        </div>
                    </div>
                </div>
        </Container>
    </>
  )
}

export default WarrantyRefundPolicy