import React from 'react'
// import BreadCrumb from '../components/BreadCrumb';
// import Meta from '../components/Meta';
import Container from '../components/Container';

const DeliveryPolicy = () => {
  return (
    <>
        {/* <Meta title={'Delivery Policy'}/>
        <BreadCrumb title="Delivery Policy"
        /> */}

        <Container class1='policy-wrapper py-5 home-wrapper-2'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='policy'>
                          <h1 className='mb-3'>Delivery Policy</h1>
                          <p className='mt-2'>At Silicon Savannah Technologies, we prioritize prompt and efficient delivery of your orders, partnering with Wells Fargo, a trusted logistics company, for our delivery services. Delivery times may vary depending on factors like product availability, shipping destination, and chosen delivery method. Upon placing your order, an estimated delivery timeframe will be provided based on your location, with shipping costs calculated accordingly.
                          </p>
                          <p className='mt-2'>After your order is processed and shipped, you’ll receive a shipping confirmation message containing a tracking number. It’s important to ensure the accuracy and completeness of the delivery address during checkout to prevent any delays or issues.</p>
                          <p className='mt-2'>Wells Fargo will make multiple delivery attempts to the provided address. In case of unsuccessful delivery after several attempts, the package may be returned to the nearest distribution center for further instructions. Once your package is successfully delivered, you’ll receive a delivery confirmation message</p>
                          <p className='mt-2'>For international shipping, we offer delivery to specified countries/regions. Please note that international shipping rates and delivery times may vary due to customs regulations and import duties of the destination country.
                          </p>
                          <p className='mt-2'>We reserve the right to update or modify this Delivery Policy at any time without prior notice, with any changes becoming effective immediately upon posting on our website.</p>
                          <p className='mt-2'>If you have any questions or concerns about our Delivery Policy, feel free to contact us at <a href="mailto:siliconsavannahtechnologies@gmail.com">siliconsavannahtechnologies@gmail.com</a>. Thank you for choosing Silicon Savannah Technologies for your laptop and computer needs!</p>
                          
                        </div>
                    </div>
                </div>
        </Container>
    </>
  )
}

export default DeliveryPolicy