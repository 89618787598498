import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { categoryService } from './categorySevice';


export const getAllCategories = createAsyncThunk("category/get", async ( thunkAPI)=>{
    try{
        return await categoryService.getAllCategory();
    } catch(error) {
        return thunkAPI.rejectWithValue(error)
    }
});

export const getACategory = createAsyncThunk("category/getById", async (id, thunkAPI)=>{
    try{
        return await categoryService.getSingleCategory(id);
    } catch(error) {
        return thunkAPI.rejectWithValue(error)
    }
});

const categoryState = {
    category:"",
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:"",
    
}

export const categorySlice = createSlice({
    name:"category",
    initialState:categoryState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getAllCategories.pending, (state)=>{
            state.isLoading=true;
        })
        .addCase(getAllCategories.fulfilled,(state,action)=> {
            state.isLoading= false;
            state.isSuccess= true;
            state.isError= false;
            state.category= action.payload;
        })
        .addCase(getAllCategories.rejected, (state,action)=>{
            state.isLoading= false;
            state.isError= true;
            state.isSuccess= false;
            state.message= action.error
        })
        .addCase(getACategory.pending, (state)=>{
            state.isLoading=true;
        })
        .addCase(getACategory.fulfilled,(state,action)=> {
            state.isLoading= false;
            state.isSuccess= true;
            state.isError= false;
            state.singleCategory= action.payload;
        })
        .addCase(getACategory.rejected, (state,action)=>{
            state.isLoading= false;
            state.isError= true;
            state.isSuccess= false;
            state.message= action.error
        })
    }
})

export default categorySlice.reducer;