import React from 'react'

const Color = () => {
  return (
    <>
        <ul className='colors ps-0'>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            
        </ul>
  </>
  )
}

export default Color