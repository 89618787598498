import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Container from '../components/Container';
// import BreadCrumb from '../components/BreadCrumb';
// import Meta from '../components/Meta';
// import ProductCard from '../components/ProductCard';
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from 'react-redux';
// import ReactImageZoom from 'react-image-zoom';
// import Color from '../components/Color';
// import { FaCodeCompare } from "react-icons/fa6";
import { FaRegHeart } from "react-icons/fa";
import { addRating, getAProduct, getAllProducts, deleteProdRating } from '../features/products/productSlice';
import { addToWishlist } from '../features/wishlist/wishlistSlice';
import { toast } from 'react-toastify';
import { getOrders } from '../features/order/orderSlice';
import { addProdToCart, getCartDetails, } from '../features/cart/cartSlice'
import { Link } from 'react-router-dom';
import { FcLike } from 'react-icons/fc';
import { MdOutlineDeleteSweep } from "react-icons/md";
import ReactImageMagnify from 'react-image-magnify';
// import watchImg from '../assets/images/watch-2.jpg';
// import watchImg1 from '../assets/images/watch.jpg';
// // import prodCompareImg from '../assets/images/prodcompare.svg';
// import viewImg from '../assets/images/view.svg';
// // import addCartImg from '../assets/images/add-cart.svg';
// import wishListImg from '../assets/images/wish.svg';

// const getWishlistfromLocalStorage = localStorage.getItem('wishlist')
//   ? JSON.parse(localStorage.getItem('wishlist'))
//   : [];

const SingleProduct = () => {
  const [quantity, setQuantity] = useState(1);
  const [alreadyAdded, setAlreadyAdded] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getProductId = location.pathname.split("/")[2];
  const [wishlist, setWishlist] = useState([]);


  
  const productState = useSelector(state => state?.product?.singleProduct);
  // const productsState = useSelector(state => state?.product?.product);
  const cartState = useSelector(state=>state?.cart?.cartProducts);
  console.log('====================================');
  console.log("cart state", cartState);
  console.log('====================================');
  const authState = useSelector(state=>state?.auth?.user);
  const orderState = useSelector(state=>state?.order?.getOrderedProducts?.orders);
  
  const [displayedImage, setDisplayedImage] = useState(null); // Initial state is the main product image
 
  
  useEffect(() => {
    if (productState?.images?.length > 0) {
      setDisplayedImage(productState.images[0].url);
    }
  }, [productState]);

  const handleClickImage = (imageUrl) => {
    setDisplayedImage(imageUrl);
  };

  
  useEffect(() => {
    dispatch(getAProduct(getProductId));
    dispatch(getCartDetails());
    dispatch(getAllProducts());
    dispatch(getOrders());
    // const storedWishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
    // setWishlist(storedWishlist);
  }, [dispatch, getProductId]);


// check and add product to wishlist
  // useEffect(() => {
  //   localStorage.setItem('wishlist', JSON.stringify(wishlist));
  // }, [wishlist]);

  const wishlistState = useSelector(state=>state?.wislist?.addToWishlist);

  const toggleWishlist = (id) => {
    const existingWishItem = wishlistState && wishlistState.includes(id);
    if (existingWishItem) {
      dispatch(addToWishlist(id));
      setWishlist(wishlist.filter((itemId) => itemId !== id));
      toast.success("Removed from wishlist");
    } else {
      dispatch(addToWishlist(id));
      setWishlist([...wishlist, id]);
      toast.success("Added to wishlist successfully");
    }
  };

  const isWishlist = wishlist.includes(getProductId);

  // check and add product to cart

  useEffect(() => {
    if (cartState) {
      for (let index = 0; index < cartState?.length; index++) {
        if (getProductId === cartState[index]?.productId?._id) {
          setAlreadyAdded(true)
        }
      }
    }
  }, [cartState, getProductId]);

  const uploadCart = async () => {
    if (quantity === "") {
      toast.error("Please enter a valid quantity number");
      return false;
    }
  
    await dispatch(addProdToCart({ productId: productState?._id, quantity, price: productState?.price })).then(()=>{
      navigate("/cart")
    })
};
  
//   const addToWish = (id)=> {
//     dispatch(addToWishlist(id));
// }
  
// check and add product rating

const [star, setStar] = useState(null);
const [comment, setComment] = useState(null);
const [showReviewForm, setShowReviewForm] = useState(false);

const addRatingToProduct = () => {
  if (star === undefined || star === null || star === 0) {
    toast.error("Please add star rating!");
    return false;
  } else if (comment === undefined || comment === null || comment.trim() === '') {
    toast.error("Please add comment");
    return false;
  } else {
    dispatch(addRating({ star: star, comment: comment, prodId: getProductId })).then(() => {
      dispatch(getAProduct(getProductId)); 
      // Reset the form state after successful submission
      setStar(0);
      setComment('');
      setShowReviewForm(false);
    });
  }
  return false;
}



const hasOrderedProduct = orderState && orderState.some(order => {
  return order.orderItems.some(item => item.product?._id === getProductId);
});

//delete product rating
const handleDeleteRating = ({ productId, ratingId }) => {
  dispatch(deleteProdRating({ productId, ratingId}))
  setTimeout(()=>{
      dispatch(getAProduct(getProductId));
  }, 200)
}

  const copyToClipboard = (text) => {
    console.log('text', text)
    var textField = document.createElement('textarea')
    textField.innerText = text
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
  }
  

  return (
    <>
        {/* <Meta title={productState?.title}/>
      <BreadCrumb title={productState?.title} /> */}

      <Container class1='main-product-wrapper py-5 home-wrapper-2 d-lg-block d-none'>
        <div className='row d-flex flex-wrap'>
          <div className='col-6 d-flex flex-column'>
          <div className='main-product-image flex-grow-1 img-fluid'>
  {displayedImage && (
    <ReactImageMagnify
      {...{
        smallImage: {
          alt: 'single product',
          isFluidWidth: true,
          src: displayedImage,
          srcSet: `${displayedImage} 355w,
          ${displayedImage} 481w,
          ${displayedImage} 584w,
          ${displayedImage} 687w,
          ${displayedImage} 770w,
          ${displayedImage} 861w,
          ${displayedImage} 955w,
          ${displayedImage} 1033w,
          ${displayedImage} 1112w,
          ${displayedImage} 1192w,
          ${displayedImage} 1200w`,
          sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px',
        },
        largeImage: {
          src: displayedImage,
          width: 788,
          height: 788,
        },
        lensStyle: { zIndex: 999, cursor: 'move' },
        enlargedImageContainerDimensions: {
      width: '120%',
      height: '120%',
    },
    enlargedImageContainerStyle: {
      maxWidth: '100%', // Limit the width to the width of the viewport
      maxHeight: '100%', // Limit the height to the height of the viewport
      left: '50% !important',
      border: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'white',
      overflow: 'hidden', // Hide any overflow
    },
    shouldHideHintAfterFirstActivation: false,
      }}
    />
  )}
</div>

      <div className='other-product-images d-flex flex-wrap gap-15'>
        {/* Display the other four images */}
        {productState?.images?.map((image, index) => (
          <div key={index} onClick={() => handleClickImage(image.url)} onMouseOver={(e) => (e.currentTarget.style.opacity = 0.7)}
              onMouseOut={(e) => (e.currentTarget.style.opacity = 1)} style={{ transition: 'opacity 0.3s', opacity: 1 }}>
            <img className='img-fluid mx-auto' src={image.url} alt='product' 
               />
          </div>
        ))}
      </div>
                  
    </div>
    <div className='col-6 d-flex flex-column'>
      <div className='main-product-details'>
        <div className='border-bottom'>
          <h3 className='title'>   
          {productState?.title}
          </h3>
        </div>
        <div className='border-bottom py-3'>
          <p  className='price'>KSh. {productState?.price}</p>
          <p dangerouslySetInnerHTML={{ __html: productState?.description }}></p>
          {/* {productState?.ratings && productState?.ratings.length > 0 ? (
            <div className='d-flex align-items-center gap-10'>
              <ReactStars
                count={5}
                size={24}
                value={parseFloat(productState?.totalrating)}
                edit={false}
                activeColor="#ffd700"
              />
              <p className='mb-0 t-review'>({productState?.ratings.length} reviews)</p>
            </div>
          ) : (
            <p>No reviews yet</p>
          )}
          
          <a className='review-btn' href='#review'>Write a review</a> */}
        </div>
        <div className='py-3'>
        
          <div className="d-flex gap-10 align-items-center my-2"><h3 className='product-heading'>Brand :</h3> <p className='product-data'>{productState?.brand}</p>

          </div>
          
          <div className="d-flex gap-10 align-items-center my-2">
            <h3 className='product-heading'>Tags :</h3> <p className='product-data'>{productState?.tags}</p>
          </div>
          <div className="d-flex gap-10 align-items-center my-2">
            <h3 className='product-heading'>Availability :</h3> <p className='product-data'>In Stock</p>
          </div>
          {/* <div className="d-flex gap-10 flex-column mt-2 mb-3">
            <h3 className='product-heading'>Size :</h3> <div className='d-flex flex-wrap gap-15'>
              <span className='badge border border-1 bg-white text-dark border-secondary'>S</span>
              <span className='badge border border-1 bg-white text-dark border-secondary'>M</span>
              <span className='badge border border-1 bg-white text-dark border-secondary'>L</span>
            </div>
          </div> */}
          {/* <div className="d-flex gap-10 flex-column mt-2 mb-3">
            <h3 className='product-heading product-color'>Color :</h3> <Color />
          </div> */}
          <div className="d-flex flex-column mt-2 mb-3">
          <div className="d-flex align-items-center gap-15">
            <h3 className='product-heading'>Quantity :</h3>
            
              <div className='product-data'>
                <input 
                  type='number'
                  name=''
                  min={1}
                  max={10}
                  className='form-control'
                  style={{ width: "70px" }}
                  id=''
                  onChange={(e) => setQuantity(e.target.value)}
                  value={quantity}
                />
              </div>
            
          </div>
          <div className="mt-3">
            <button
              onClick={() => {alreadyAdded ? navigate('/cart') : uploadCart()}}
              type='button'
              className='button border-0'>
                {alreadyAdded ? "View in cart" : "Add to cart"}
            </button>
          </div>
          </div>
          
          <div className='d-flex align-items-center gap-15 mt-3 mb-3'>
            <div onClick={(e) => toggleWishlist(getProductId)}>
              {isWishlist? <Link ><FcLike className='fs-4 me-2'/>View in Wishlist</Link> : <Link ><FaRegHeart className='fs-5 me-2' />Add to Wishlist</Link>}
            </div>
            {/* <div>
              <Link><FaCodeCompare className='fs-5 me-2' />Add to Compare</Link>
            </div> */}
          </div>
          <div className="d-flex flex-column gap-10 my-3"><h3 className='product-heading'>Delivery & Returns :</h3> <p className='product-data'>At Silicon Savannah Technologies, we prioritize prompt and efficient delivery of your orders, partnering with Wells Fargo, a trusted logistics company, for our delivery services. Delivery times may vary depending on factors like product availability, shipping destination, and chosen delivery method. View <Link to='/delivery-policy'>Dellivery Policy.</Link> for more details</p>

          </div>
          <div className="d-flex gap-10 align-items-center my-3"><h3 className='product-heading'>Share :</h3>
          <button onClick={() => {
            copyToClipboard(window.location.href)}}>
              Copy Product Link
            </button>
          </div>
        </div>
      </div>
                </div>
            </div>
      </Container>

      <Container class1='main-product-wrapper py-3 home-wrapper-2 d-lg-none'>
        <div className='row d-flex flex-wrap'>
          <div className='col-12 d-flex flex-column'>
          <div className='main-product-image flex-grow-1 img-fluid'>
          {displayedImage && (
              <img src={displayedImage} alt='single product' className='img-fluid' />
            )}
</div>

      <div className='other-product-images flex-wrap'>
        {/* Display the other four images */}
        {productState?.images?.map((image, index) => (
          <div key={index} className='col-6'>
          <div className='other-prod' onClick={() => handleClickImage(image.url)} onMouseOver={(e) => (e.currentTarget.style.opacity = 0.7)}
              onMouseOut={(e) => (e.currentTarget.style.opacity = 1)} style={{ transition: 'opacity 0.3s', opacity: 1 }}>
            <img className='img-fluid mx-auto' src={image.url} alt='product' 
               />
          </div>
          </div>
          
        ))}
      
        
      </div>
                  
    </div>
    <div className='col-12 d-flex flex-column'>
      <div className='main-product-details'>
                    <div className='border-bottom'>
                      <h3 className='title'>   
                      {productState?.title}
                      </h3>
                    </div>
                    <div className='border-bottom py-3'>
                      <p  className='price'>KSh. {productState?.price}</p>
                      <p dangerouslySetInnerHTML={{ __html: productState?.description }}></p>
                      {/* {productState?.ratings && productState?.ratings.length > 0 ? (
                        <div className='d-flex align-items-center gap-10'>
                          <ReactStars
                            count={5}
                            size={24}
                            value={parseFloat(productState?.totalrating)}
                            edit={false}
                            activeColor="#ffd700"
                          />
                          <p className='mb-0 t-review'>({productState?.ratings.length} reviews)</p>
                        </div>
                      ) : (
                        <p>No reviews yet</p>
                      )}
                      
                      <a className='review-btn' href='#review'>Write a review</a> */}
                    </div>
                    <div className='py-3'>
                    
                      <div className="d-flex gap-10 align-items-center my-2"><h3 className='product-heading'>Brand :</h3> <p className='product-data'>{productState?.brand}</p>

                      </div>
                      
                      <div className="d-flex gap-10 align-items-center my-2">
                        <h3 className='product-heading'>Tags :</h3> <p className='product-data'>{productState?.tags}</p>
                      </div>
                      <div className="d-flex gap-10 align-items-center my-2">
                        <h3 className='product-heading'>Availability :</h3> <p className='product-data'>In Stock</p>
                      </div>
                      {/* <div className="d-flex gap-10 flex-column mt-2 mb-3">
                        <h3 className='product-heading'>Size :</h3> <div className='d-flex flex-wrap gap-15'>
                          <span className='badge border border-1 bg-white text-dark border-secondary'>S</span>
                          <span className='badge border border-1 bg-white text-dark border-secondary'>M</span>
                          <span className='badge border border-1 bg-white text-dark border-secondary'>L</span>
                        </div>
                      </div> */}
                      {/* <div className="d-flex gap-10 flex-column mt-2 mb-3">
                        <h3 className='product-heading product-color'>Color :</h3> <Color />
                      </div> */}
                      <div className="d-flex flex-column mt-2 mb-3">
                      <div className="d-flex align-items-center gap-15">
                        <h3 className='product-heading'>Quantity :</h3>
                       
                          <div className='product-data'>
                            <input 
                              type='number'
                              name=''
                              min={1}
                              max={10}
                              className='form-control'
                              style={{ width: "70px" }}
                              id=''
                              onChange={(e) => setQuantity(e.target.value)}
                              value={quantity}
                            />
                          </div>
                        
                      </div>
                      <div className="mt-3">
                        <button
                          onClick={() => {alreadyAdded ? navigate('/cart') : uploadCart()}}
                          type='button'
                          className='button border-0'>
                            {alreadyAdded ? "View in cart" : "Add to cart"}
                        </button>
                      </div>
                      </div>
                      
                      <div className='d-flex align-items-center gap-15 mt-3 mb-3'>
                        <div onClick={(e) => toggleWishlist(getProductId)}>
                          {isWishlist? <Link ><FcLike className='fs-4 me-2'/>View in Wishlist</Link> : <Link ><FaRegHeart className='fs-5 me-2' />Add to Wishlist</Link>}
                        </div>
                        {/* <div>
                          <Link><FaCodeCompare className='fs-5 me-2' />Add to Compare</Link>
                        </div> */}
                      </div>
                      <div className="d-flex flex-column gap-10 my-3"><h3 className='product-heading'>Delivery & Returns :</h3> <p className='product-data'>At Silicon Savannah Technologies, we prioritize prompt and efficient delivery of your orders, partnering with Wells Fargo, a trusted logistics company, for our delivery services. Delivery times may vary depending on factors like product availability, shipping destination, and chosen delivery method. View <Link style={{textDecoration: 'underline'}} className='text-dark' to='/delivery-policy'>Delivery Policy</Link> for more details</p>

                      </div>
                      <div className="d-flex gap-10 align-items-center my-3"><h3 className='product-heading'>Share :</h3>
                      <button onClick={() => {
                        copyToClipboard(window.location.href)}}>
                          Copy Product Link
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
      </Container>

      <Container class1='description-wrapper py-5 home-wrapper-2'>
          <div className='row'>
            <div className='col-12'>
              <h4>Description</h4>
              <div className='bg-white p-3'>
              
              <p dangerouslySetInnerHTML={{ __html: productState?.description }}>
              </p>
              </div>
            </div>
          </div>
      </Container>
      <Container class1='reviews-wrapper home-wrapper-2'>
          <div className='row'>
            <div className='col-12'>
              <h3 id='review'>Reviews</h3>
              <div className='review-inner-wrapper'>
              <div className='review-head d-flex justify-content-between align-items-end'>
                <div>
                  <h4 className='mb-2'>Customer Reviews</h4>
                  {productState?.ratings && productState?.ratings.length > 0 ? (
                        <div className='d-flex align-items-center gap-10'>
                          <ReactStars
                            count={5}
                            size={24}
                            value={parseFloat(productState?.totalrating)}
                            edit={false}
                            activeColor="#ffd700"
                          />
                          <p className='mb-0 d-lg-block d-none'>Based on {productState?.ratings.length} reviews</p>
                        </div>
                      ) : (
                        <p>No reviews yet</p>
                      )}
                  
                </div>
                
                
                  <div>
                  <Link className='text-dark text-decoration-underline' onClick={() => {
        if (!hasOrderedProduct) {
          alert('You must place an order before writing a review.');
        } else {
          setShowReviewForm(true);
        }
      }}>Write a review</Link>
                </div>
              </div>
              {
                  showReviewForm && hasOrderedProduct && (
                    <div className='review-form py-4'>
              <h4>Write a review</h4>
              
                    <div>
                    <ReactStars
                      count={5}
                      size={24}
                      value={0}
                      edit={true}
                      activeColor="#ffd700"
                      onChange={(e)=>{
                        setStar(e)
                      }}
                    />
                    </div>
                    <div>
                      <textarea
                      name=''
                      id=''
                      className='w-100 form-control'
                      cols="30"
                      rows="4"
                      placeholder='Comments'
                      onChange={(e)=>{
                        setComment(e.target.value)
                      }}
                      ></textarea>
                    </div>
                    <div className='d-flex justify-content-end mt-3'>
                      <button onClick={addRatingToProduct} type="button" className='button border-0'>Submit Review</button>
                    </div>
                  
              </div>
                  )
                }
              
              <div className='reviews mt-4 d-lg-block d-none'>
                {
                  productState && productState?.ratings?.map((item,index)=>{
                    const isOwner = item?.postedby._id === authState?._id;
                    const username = item?.postedby?.username || "Unknown User";
                    return (
                      <div key={index} className='review d-flex justify-content-between align-items-end'>
                        <div>
                          <div className='d-flex gap-10 align-items-center'>
                            <h6 className='mb-0'>{username}</h6>
                            <ReactStars
                              count={5}
                              size={24}
                              value={item?.star}
                              edit={false}
                              activeColor="#ffd700"
                            />
                          </div>
                          <p className='mt-3'>{item?.comment}</p>
                        </div>
                        {isOwner && (
                          <MdOutlineDeleteSweep className='delete-review fs-2' onClick={() => handleDeleteRating({productId: getProductId, ratingId: item?._id})} />
                        )}
                  
                </div> 
                    )
                  })
                }
              </div>
              <div className='reviews mt-4 d-lg-none'>
                {
                  productState && productState?.ratings?.map((item,index)=>{
                    const isOwner = item?.postedby._id === authState?._id;
                    const username = item?.postedby?.username || "Unknown User";
                    return (
                      <div key={index} className='review d-flex justify-content-between'>
                        <div>
                          <div className='d-flex gap-10 align-items-center'>
                            <h6 className='mb-0'>{username}</h6>
                            <ReactStars
                              count={5}
                              size={24}
                              value={item?.star}
                              edit={false}
                              activeColor="#ffd700"
                            />
                          </div>
                          <p className='mt-3'>{item?.comment}</p>
                        </div>
                        {isOwner && (
                          <MdOutlineDeleteSweep className='delete-review fs-2' onClick={() => handleDeleteRating({productId: getProductId, ratingId: item?._id})} />
                        )}
                  
                </div> 
                    )
                  })
                }
              </div>
              </div>
            </div>
          </div>
      </Container>
      {/* <Container class1='popular-wrapper py-5 home-wrapper-2'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>
              Relevant Products
              </h3>
            </div>
            <div className='row'>
            {Array.isArray(productsState) && productsState.map((item, index) => {
              let hasPopularProduct = false;
              for (let i = 0; i < item.tags.length; i++) {
                if (item.tags[i] === 'popular') {
                  hasPopularProduct = true;
                  break; // Exit the loop since we found a special product
                }
              }

                return( hasPopularProduct ? 
                    <div
                    key={index}
                    className="col-3">
        
        <div
        className='product-card position-relative'>
            <div className='wishlist-icon position-absolute'>
                <button className='border-0 bg-transparent' onClick={(e)=>{addToWish(item?._id)}}>
                    <img src={wishListImg} alt="wish list" />
                </button>
            </div>
            <div className='product-image' onClick={()=>navigate("/product/"+item?._id)} src={viewImg} alt='view'>
                <img className='img-fluid mx-auto' src={watchImg1} alt='product'/>
                <img className='img-fluid mx-auto' src={watchImg} alt='product'/>
            </div>
            <div className='product-details' onClick={()=>navigate("/product/"+item?._id)} src={viewImg} alt='view'>
                <h6 className='brand'>{item?.brand}</h6>
                <h5 className='product-title'>
                    {item?.title}
                </h5>
                <ReactStars
                    count={5}
                    size={24}
                    value={parseFloat(item?.totalrating)}
                    edit={false}
                    activeColor="#ffd700"
                />
                
                <p className="price">KSh. {item?.price}</p>
            </div>
            <div className='action-bar position-absolute'>
                <div className='d-flex flex-column gap-15'>
                    <button onClick={()=>navigate("/product/"+item?._id)} className='border-0 bg-transparent'>
                        <img src={viewImg} alt='view'/>
                    </button>
                </div>
            </div>
        </div>
    </div> : null
                )
            })
        }
        {(!productState || productState.length === 0) && <p>No Products Available In This Category</p>}
            </div>
          </div>
      </Container> */}
    </>
  )
}

export default SingleProduct