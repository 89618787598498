import React from 'react'

const Container = (props) => {
  return (
    <section className={props.class1}>
        <div className='container-fluid' 
        // style={{ paddingLeft: '85px', paddingRight: '85px', 
        //   '@media (min-width: 576px)': {
        //     paddingLeft: '20px',
        //     paddingRight: '20px',
        //   },
        //   '@media (min-width: 768px)': {
        //     paddingLeft: '30px',
        //     paddingRight: '30px',
        //   },
        //   '@media (min-width: 992px)': {
        //     paddingLeft: '40px',
        //     paddingRight: '40px',
        //   },
        // }}
        >
          {props.children}
        </div>
    </section>
  )
}

export default Container