import React, { useEffect, useState, useRef } from 'react';
import { Carousel } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Marquee from "react-fast-marquee";
import SpecialProduct from '../components/SpecialProduct';
import Container from '../components/Container';
import serviceImg1 from '../assets/images/service.png';
import serviceImg2 from '../assets/images/service-02.png';
import serviceImg3 from '../assets/images/service-03.png';
import serviceImg4 from '../assets/images/service-04.png';
import serviceImg5 from '../assets/images/service-05.png';
import mainBannerImg1 from '../assets/images/Main4.png';
import mainBannerImg2 from '../assets/images/Main2.png';
import mainBannerImg3 from '../assets/images/Main3.png';
import mainBannerImg4 from '../assets/images/Main4.png';
import mainBannerImg5 from '../assets/images/Main5.png';
import brandImg1 from '../assets/images/brand-01.png';
import brandImg2 from '../assets/images/brand-02.png';
import brandImg3 from '../assets/images/brand-03.png';
import brandImg4 from '../assets/images/brand-04.png';
import brandImg5 from '../assets/images/brand-05.png';
import brandImg6 from '../assets/images/brand-06.png';
import brandImg7 from '../assets/images/brand-07.png';
import brandImg8 from '../assets/images/brand-08.png';
import laptopImg from '../assets/images/laptop.jpg'
import monitorImg from '../assets/images/monitor.png' 
import desktopImg from '../assets/images/desktop-1.png' 
import cameraImg from '../assets/images/cctv.jpeg' 
import printerImg from '../assets/images/printer.jpeg' 
import routerImg from '../assets/images/router.png'
import mouseImg from '../assets/images/mouse.png'  
import webImg from '../assets/images/solution.png'   
import { useDispatch, useSelector } from 'react-redux';
import { addToWishlist, getUserProductWishlist } from '../features/wishlist/wishlistSlice';
import { getAllProducts } from '../features/products/productSlice';
import { addProdToCart, getCartDetails, updateCartProd } from '../features/cart/cartSlice';
import { getAllCategories } from '../features/categories/categorySlice';
import ReactStars from "react-rating-stars-component";
// import prodCompareImg from '../assets/images/prodcompare.svg';
import viewImg from '../assets/images/view.svg';
import addCartImg from '../assets/images/add-cart.svg';
import wishListImg from '../assets/images/wish.svg';
import { FcLike } from 'react-icons/fc';
import { toast } from "react-toastify";


const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productState = useSelector((state)=> state?.product?.product);
  const categoryState = useSelector((state)=> state?.category?.category);
  const userCartState = useSelector(state=>state?.cart?.cartProducts);
  const carouselRef = useRef(null);


  // const getProducts = useCallback(() => {
  //   dispatch(getAllProducts());
  // }, [dispatch]);

useEffect(()=>{
  dispatch(getAllCategories());
  dispatch(getAllProducts());
  dispatch(getCartDetails());
}, [dispatch]);


// Fetch wishlist state
const wishlistState = useSelector(state => state?.wishlist?.wishlist[0]?.wishlist);

// Initialize wishlist state after wishlistState is fetched
useEffect(() => {
  if (wishlistState) {
    setWishlist(wishlistState.map(item => item._id));
  }
}, [wishlistState]);

// State to hold wishlist
const [wishlist, setWishlist] = useState([]);

useEffect(() => {
  dispatch(getUserProductWishlist());
}, [dispatch]);

// Toggle wishlist
const toggleWishlist = (id) => {
  const existingWishItem = wishlist.includes(id);

  if (existingWishItem) {
    dispatch(addToWishlist(id)).then(() => dispatch(getUserProductWishlist()));
    setWishlist(wishlist.filter((itemId) => itemId !== id));
    toast.info("Removed from wishlist");
  } else {
    dispatch(addToWishlist(id)).then(() => dispatch(getUserProductWishlist()));
    setWishlist([...wishlist, id]);
    toast.success("Added to wishlist successfully");
  }
};

// add to cart




const addToCart = async (productId, price) => {
  const existingItem = userCartState && userCartState.find((item) => item.productId._id === productId);
  if (existingItem) {
    const newQuantity = existingItem.quantity + 1;
    await dispatch(updateCartProd({ cartItemId: existingItem._id, quantity: newQuantity }));
  } else {
    await dispatch(addProdToCart({ productId, quantity: 1, price }));
  }

  await dispatch(getCartDetails());
  navigate('/cart');
};
// scrolling
const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};
const categories = [
  { title: 'Laptops', img: laptopImg },
  { title: 'Monitors', img: monitorImg },
  { title: 'Desktops', img: desktopImg },
  { title: 'Printers & Scanners', img: printerImg },
  { title: 'CCTV Cameras', img: cameraImg },
  { title: 'Networking', img: routerImg },
  { title: 'Accessories', img: mouseImg },
  { title: 'IT Solutions', img: webImg },
];

const featuredProducts = productState && productState.filter(item => item.tags === 'featured');
  const chunkSize = 2; // Number of items to display side by side
  const chunks = [...Array(Math.ceil(featuredProducts.length / chunkSize))].map((_, index) =>
    featuredProducts.slice(index * chunkSize, (index + 1) * chunkSize)
  );

  const specialProducts = productState && productState.filter(item => item.tags === 'popular');
  const popularChunks = [...Array(Math.ceil(specialProducts.length / chunkSize))].map((_, index) =>
    specialProducts.slice(index * chunkSize, (index + 1) * chunkSize)
  );
  

  const handlePrev = () => {
    carouselRef.current.prev();
  };

  const handleNext = () => {
    carouselRef.current.next();
  };


  return (
    <>
    <Container class1='home-wrapper-1 py-4' style={{ overflow: 'hidden' }}>
    <div className='row'>
        <div className='col-3 d-lg-block d-none'>
          <div className="list-group" onClick={scrollToTop}>
            <Link type="button" className="list-group-item list-group-item-action custom-active-button text-capitalize" aria-current="true">
              QUICK LINKS
            </Link>
            {
              categoryState && categoryState.map((item)=>{
                return (
                  <Link key={item?._id} type="button" className="list-group-item list-group-item-action text-capitalize" to={`/product?category=${item?.title}`}>{item?.title}</Link>
                )
              })
            }
            
            
          </div>
        </div>

      
            <div className="col-6 carousel slide" id="carouselExampleControls" data-bs-ride="carousel" data-bs-interval="2500" >
              <div className="carousel-inner">
                <div className='main-banner position-relative carousel-item active'>
                  <img
                    src={mainBannerImg1}
                    alt='main banner'
                    className='img-fluid rounded d-block w-100'
                  />
                  {/* <div className='main-banner-content position-absolute'>
                    <h4>SUPERCHARGED FOR PROS</h4>
                    <h5>iPad S13+ Pro</h5>
                    <p>From $999.00 or $41.62/mo</p>
                    <Link className='button'>BUY NOW</Link>
                  </div> */}
                </div>
                <div className='main-banner position-relative carousel-item'>
                  <img
                    src={mainBannerImg2}
                    alt='main banner'
                    className='img-fluid rounded d-block w-100'
                  />
                  {/* <div className='main-banner-content position-absolute'>
                    <h4>SUPERCHARGED FOR PROS</h4>
                    <h5>iPad S13+ Pro</h5>
                    <p>From $999.00 or $41.62/mo</p>
                    <Link className='text-black'>BUY NOW</Link>
                  </div> */}
                </div>
                <div className='main-banner position-relative carousel-item'>
                <img
                    src={mainBannerImg3}
                    alt='main banner'
                    className='img-fluid rounded d-block w-100'
                  />
                </div>
                <div className='main-banner position-relative carousel-item'>
                <img
                    src={mainBannerImg4}
                    alt='main banner'
                    className='img-fluid rounded d-block w-100'
                  />
                </div>
                <div className='main-banner position-relative carousel-item'>
                <img
                    src={mainBannerImg5}
                    alt='main banner'
                    className='img-fluid rounded d-block w-100'
                  />
                </div>
                <div className='main-banner position-relative carousel-item'>
                <img
                    src={mainBannerImg4}
                    alt='main banner'
                    className='img-fluid rounded d-block w-100'
                  />
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
            <div className="col-3 d-lg-block d-none">
              <div className='d-flex flex-wrap gap-15 justify-content-between align-items-center'>
              <div className='small-banner position-relative'>
                <img
                  src={mainBannerImg5}
                  alt='main banner'
                  className='img-fluid rounded'
                  style={{ objectFit: "cover" }}
                />
                {/* <div className='small-banner-content position-absolute'>
                  <h4>BEST SALE</h4>
                  <h5>Laptops Max</h5>
                  <p>From $1699.00 <br/> or $64.62/mo</p>
                  
                </div> */}
              </div>
              <div className='small-banner position-relative'>
                <img
                  src={mainBannerImg4}
                  alt='main banner'
                  className='img-fluid rounded'
                />
                {/* <div className='small-banner-content position-absolute'>
                  <h4>NEW ARRIVAL</h4>
                  <h5>Buy iPad Air</h5>
                  <p>From $599.00 <br/> or $41.62/mo</p>
                  
                </div>*/}
               </div>  
              </div>
            </div>
          </div>
    </Container>
      
    <Container class1='home-wrapper-2 py-5 d-lg-block d-none'>
    <div className="row">
            <div className="col-12 services-wrapper">
              <div className='services d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center gap-15'>
                  <img src={serviceImg1} alt='services'/>
                  <div>
                    <h6>Express Delivery</h6>
                    <p className='mb-0'>For all orders</p>
                  </div>
                </div>
                <div className='d-flex align-items-center gap-15'>
                  <img src={serviceImg2} alt='services'/>
                  <div>
                    <h6>Daily Surprise Offers</h6>
                    <p className='mb-0'>Save upto 25% off</p>
                  </div>
                </div>
                <div className='d-flex align-items-center gap-15'>
                  <img src={serviceImg3} alt='services'/>
                  <div>
                    <h6>Support 24/7</h6>
                    <p className='mb-0'>Shop with an expert</p>
                  </div>
                </div>
                <div className='d-flex align-items-center gap-15'>
                  <img src={serviceImg4} alt='services'/>
                  <div>
                    <h6>Affordable Prices</h6>
                    <p className='mb-0'>Get factory default price</p>
                  </div>
                </div>
                <div className='d-flex align-items-center gap-15'>
                  <img src={serviceImg5} alt='services'/>
                  <div>
                    <h6>Secure Payments</h6>
                    <p className='mb-0'>100% protected payments</p>
                  </div>
                </div>
              </div>
              </div>
            </div>
    </Container>
    
    <Container class1='home-wrapper-2 py-4 d-lg-none'>
    <div className="row">
            <div className="col-12 services">
            <Carousel interval={25000} controls={false} indicators={false}>
        <Carousel.Item>
              <div className='d-flex align-items-center gap-15'>
                  <img src={serviceImg1} alt='services'/>
                  <div>
                    <h6>Express Delivery</h6>
                    <p className='mb-0'>For all orders</p>
                  </div>
                </div>
                </Carousel.Item>
        <Carousel.Item>
                <div className='d-flex align-items-center gap-15'>
                  <img src={serviceImg2} alt='services'/>
                  <div>
                    <h6>Daily Surprise Offers</h6>
                    <p className='mb-0'>Save upto 25% off</p>
                  </div>
                </div>
                </Carousel.Item>
        <Carousel.Item>
                <div className='d-flex align-items-center gap-15'>
                  <img src={serviceImg3} alt='services'/>
                  <div>
                    <h6>Support 24/7</h6>
                    <p className='mb-0'>Shop with an expert</p>
                  </div>
                </div>
                </Carousel.Item>
        <Carousel.Item>
                <div className='d-flex align-items-center gap-15'>
                  <img src={serviceImg4} alt='services'/>
                  <div>
                    <h6>Affordable Prices</h6>
                    <p className='mb-0'>Get factory default price</p>
                  </div>
                </div>
                </Carousel.Item>
        <Carousel.Item>
                <div className='d-flex align-items-center gap-15'>
                  <img src={serviceImg5} alt='services'/>
                  <div>
                    <h6>Secure Payments</h6>
                    <p className='mb-0'>100% protected payments</p>
                  </div>
                </div>
                </Carousel.Item>
                </Carousel>
              </div>
              </div>
            
    </Container>

      <Container class1='home-wrapper-2 py-5 d-lg-block d-none'>
        <div className='row'>
          <div className='col-12'>
            <h3 className='section-heading'>
            Popular Categories
            </h3>
          </div>
        </div>
        
          <div className="row">
            <div className="col-12">
              <div className='categories d-flex justify-content-between flex-wrap align-items-center'>
              <div onClick={() => {
      navigate('/product?category=Laptops');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }} className=' d-flex gap align-items-center ' >
                  <div>
                    <h6>Laptops</h6>
                    
                  </div>
                  <img className='img-fluid category-img zoom' src={laptopImg} alt='camera'/>
                </div>
                <div onClick={() => {navigate('/product?category=Monitors'); window.scrollTo({ top: 0, behavior: 'smooth' });}} className='d-flex gap align-items-center'>
                  <div>
                    <h6>Monitors</h6>
                    
                  </div>
                  <img className='img-fluid category-img zoom' src={monitorImg} alt='camera'/>
                </div>
                
                <div onClick={() => {navigate('/product?category=Desktops'); window.scrollTo({ top: 0, behavior: 'smooth' });}} className='d-flex gap align-items-center'>
                  <div>
                    <h6>Desktops</h6>
                    
                  </div>
                  <img className='img-fluid category-img zoom' src={desktopImg} alt='camera'/>
                </div>
                
                <div onClick={() => {navigate('/product?category=Printers And Scanners'); window.scrollTo({ top: 0, behavior: 'smooth' });}} className='d-flex gap align-items-center'>
                  <div>
                    <h6>Printers & Scanners</h6>
                    
                  </div>
                  <img className='img-fluid category-img zoom' src={printerImg} alt='camera'/>
                </div>
                
                
                <div onClick={() => {navigate('/product?category=CCTV Cameras'); window.scrollTo({ top: 0, behavior: 'smooth' });}} className='d-flex gap align-items-center'>
                  <div>
                    <h6>CCTV Cameras</h6>
                    
                  </div>
                  <img className='img-fluid category-img zoom' src={cameraImg} alt='camera'/>
                </div>
                <div onClick={() => {navigate('/product?category=Networking'); window.scrollTo({ top: 0, behavior: 'smooth' });}} className='d-flex gap align-items-center'>
                  <div>
                    <h6>Networking</h6>
                    
                  </div>
                  <img className='img-fluid category-img zoom' src={routerImg} alt='camera'/>
                </div>
                <div onClick={() => {navigate('/product?category=Accessories'); window.scrollTo({ top: 0, behavior: 'smooth' });}} className='d-flex gap align-items-center'>
                  <div>
                    <h6>Accessories</h6>
                    
                  </div>
                  <img className='img-fluid category-img zoom' src={mouseImg} alt='camera'/>
                </div>
                <div onClick={() => {navigate('/services'); window.scrollTo({ top: 0, behavior: 'smooth' });}} className='d-flex gap align-items-center'>
                  <div>
                    <h6>IT Solutions</h6>
                    
                  </div>
                  <img className='img-fluid category-img zoom' src={webImg} alt='camera'/>
                </div>
              </div>
            </div>
          </div>
      </Container>

      <Container class1='home-wrapper-2 d-lg-none'>
        
        <Carousel indicators={false}>
        {[...Array(Math.ceil(categories.length / 2))].map((_, index) => (
          <Carousel.Item key={index}>
            <div className='row carousel-container'>
              {categories.slice(index * 2, index * 2 + 2).map((category, i) => (
                <div key={i} className='col-md-6' onClick={() => {
                  navigate(`/product?category=${category.title}`);
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}>
                  <div className='d-flex gap align-items-center carousel-cat'>
                    <div>
                      <h6>{category.title}</h6>
                    </div>
                    <img className='img-fluid category-img zoom' src={category.img} alt='category'/>
                  </div>
                </div>
              ))}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      </Container>

      <Container class1='featured-wrapper py-5 home-wrapper-2 d-lg-block d-none'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>
              Featured Collection
              </h3>
            </div>
          </div>
          <div className='row'>
            {Array.isArray(productState) && productState?.map((item, index) => {
              const isWishlist = wishlist.some(itemId => itemId === item._id);
              let hasFeaturedProduct = false;
              if (item?.tags === 'featured') {
                hasFeaturedProduct = true;
              }

                return( hasFeaturedProduct ? 
                    <div
                    key={index}
                    className="col-3 prod-card">
        {/**REVISIT */}
        <div
        className='product-card position-relative'>
            <div className='wishlist-icon position-absolute'>
                <button className='border-0 bg-transparent' onClick={(e) => toggleWishlist(item?._id)}>
                {isWishlist ? <FcLike /> : <img src={wishListImg} alt="wish list" />}
                <span className="like-text">Add to wishlist</span>
                </button>
            </div>
            <div className='product-image' onClick={()=>{navigate("/product/"+item?._id); window.scrollTo({ top: 0, behavior: 'smooth' });}} src={viewImg} alt='view'>
              <img className='img-fluid mx-auto' src={item?.images[0]?.url} alt='product'/>
              <img className='img-fluid mx-auto' src={item?.images[1]?.url} alt='product'/>
            </div>
            <div className='product-details mt-3' onClick={()=>{navigate("/product/"+item?._id); window.scrollTo({ top: 0, behavior: 'smooth' });}} src={viewImg} alt='view'>
                <h6 className='brand'>{item?.brand}</h6>
                <h5 className='product-title'>
                    {item?.title}
                </h5>
                <ReactStars
                    count={5}
                    size={24}
                    value={parseFloat(item?.totalrating)}
                    edit={false}
                    activeColor="#ffd700"
                />
                
                <p className="price">KSh. {item?.price}</p>
            </div>
            <div className='action-bar position-absolute'>
                <div className='d-flex flex-column gap-15'>
                    {/* <button className='border-0 bg-transparent'>
                        <img src={prodCompareImg} alt='compare'/>
                    </button> */}
                    
                    <button onClick={() => addToCart(item?._id, item?.price)} className='border-0 bg-transparent cart-img'>
                        <img src={addCartImg} alt='add cart'/>
                        <span className="cart-text">Add to cart</span>
                    </button>
                    <button className='border-0 bg-transparent view-img'>
                        <img onClick={()=>{navigate("/product/"+item?._id); window.scrollTo({ top: 0, behavior: 'smooth' });}} src={viewImg} alt='view'/>
                        <span className="view-text">Quick view</span>
                    </button>
                </div>
            </div>
        </div>
    </div> : null
                )
            })
        }

        {(!productState || productState.length === 0) && <p>No Products Available In This Category</p>}

    </div>
      </Container>

      <Container class1='featured-wrapper py-5 home-wrapper-2 d-lg-none'>
      <div className='row'>
            <div className='col-12'>
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                <h3 className='section-heading'>
                Featured Collection
                </h3>
              </div>
              <div className='custom-controls'>
                <button onClick={handlePrev} className='custom-prev border-0'>
                  {'<'}
                </button>
                <button onClick={handleNext} className='custom-next border-0'>
                  {'>'}
                </button>
          </div>
            </div>
            </div>
          </div>
      <div className='row'>
        <div className='col-12 d-flex justify-content-between align-items-center'>
          <Carousel controls={false} indicators={false} interval={null} ref={carouselRef}>
            {chunks.map((chunk, index) => (
              <Carousel.Item key={index}>
                <div className='row'>
                  {chunk.map((item, i) => {
                    const isWishlist = wishlist.some(itemId => itemId === item._id);
                    return (
                      <div key={i} className='col-6'>
                        <div className='product-card position-relative'>
                          <div className='wishlist-icon position-absolute'>
                            <button className='border-0 bg-transparent' onClick={() => toggleWishlist(item?._id)}>
                              {isWishlist ? <FcLike /> : <img src={wishListImg} alt="wish list" />}
                              <span className="like-text">Add to wishlist</span>
                            </button>
                          </div>
                          <div className='product-image' onClick={() => { navigate("/product/" + item?._id); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>
                            <img className='img-fluid mx-auto' src={item?.images[0]?.url} alt='product' />
                            <img className='img-fluid mx-auto' src={item?.images[1]?.url} alt='product' />
                          </div>
                          <div className='product-details mt-3' onClick={() => { navigate("/product/" + item?._id); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>
                            <h6 className='brand'>{item?.brand}</h6>
                            <h5 className='product-title'>
                              {item?.title}
                            </h5>
                            <ReactStars
                              count={5}
                              size={24}
                              value={parseFloat(item?.totalrating)}
                              edit={false}
                              activeColor="#ffd700"
                            />
                            <p className="price">KSh. {item?.price}</p>
                          </div>
                          <div className='action-bar position-absolute'>
                            <div className='d-flex flex-column gap-15'>
                              <button onClick={() => addToCart(item?._id, item?.price)} className='border-0 bg-transparent cart-img'>
                                <img src={addCartImg} alt='add cart' />
                                <span className="cart-text">Add to cart</span>
                              </button>
                              <button className='border-0 bg-transparent view-img'>
                                <img onClick={() => { navigate("/product/" + item?._id); window.scrollTo({ top: 0, behavior: 'smooth' }); }} src={viewImg} alt='view' />
                                <span className="view-text">Quick view</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
        {(!productState || productState.length === 0) && <p>No Products Available In This Category</p>}
      </div>
      </Container>

      <Container class1='featured-wrapper py-5 home-wrapper-2 d-lg-block d-none'>
      <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>Top Deals</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className='famous-card position-relative zoom'>
              <img className='img-fluid' src={mainBannerImg1} alt='famous'/>
                {/* <div className='famous-content position-absolute'>
                    <h5>Big Screen</h5>
                    <h6>Smart Watch Series 7</h6>
                    <p>From $699 or $116.58/mo. for 12 mo.*</p>
                </div> */}
              </div>
            </div>
            <div className="col-3">
              <div className='famous-card position-relative zoom'>
              <img className='img-fluid' src={mainBannerImg2} alt='famous'/>
                {/* <div className='famous-content position-absolute'>
                    <h5 className='text-dark' >Studio Display</h5>
                    <h6 className='text-dark' >600 nits of brightness</h6>
                    <p className='text-dark'>27-inch 5K Retina display</p>
                </div> */}
              </div>
            </div>
            <div className="col-3">
              <div className='famous-card position-relative zoom'>
              <img className='img-fluid' src={mainBannerImg3} alt='famous'/>
                {/* <div className='famous-content position-absolute'>
                    <h5 className='text-dark' >Smart Phones</h5>
                    <h6 className='text-dark' >Smart Phone 13 pro</h6>
                    <p className='text-dark'>Now in Green. From $999.00 or $41.62/mo.
                    for 24 mo. Footnote*</p>
                </div> */}
              </div>
            </div>
            <div className="col-3">
              <div className='famous-card position-relative zoom'>
              <img className='img-fluid' src={mainBannerImg4} alt='famous'/>
                {/* <div className='famous-content position-absolute'>
                    <h5 className='text-dark' >Home Speakers</h5>
                    <h6 className='text-dark' >Room-filling sound</h6>
                    <p className='text-dark'>From $699 or $116.58/mo. for 12 mo.*</p>
                </div> */}
              </div>
            </div>
          </div>
      </Container>

      <Container class1='featured-wrapper home-wrapper-2 d-lg-none'>
      <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>Top Deals</h3>
            </div>
          </div>
          <div className="row">
          <Carousel interval={5000} controls={false} indicators={false}>
          <Carousel.Item>
          <div className="col-12">
              <div className='famous-card position-relative'>
              <img className='img-fluid' src={mainBannerImg1} alt='famous'/>
                {/* <div className='famous-content position-absolute'>
                    <h5>Big Screen</h5>
                    <h6>Smart Watch Series 7</h6>
                    <p>From $699 or $116.58/mo. for 12 mo.*</p>
                </div> */}
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
          <div className="col-12">
              <div className='famous-card position-relative'>
              <img className='img-fluid' src={mainBannerImg2} alt='famous'/>
                {/* <div className='famous-content position-absolute'>
                    <h5 className='text-dark' >Studio Display</h5>
                    <h6 className='text-dark' >600 nits of brightness</h6>
                    <p className='text-dark'>27-inch 5K Retina display</p>
                </div> */}
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
          <div className="col-12">
              <div className='famous-card position-relative'>
              <img className='img-fluid' src={mainBannerImg3} alt='famous'/>
                {/* <div className='famous-content position-absolute'>
                    <h5 className='text-dark' >Smart Phones</h5>
                    <h6 className='text-dark' >Smart Phone 13 pro</h6>
                    <p className='text-dark'>Now in Green. From $999.00 or $41.62/mo.
                    for 24 mo. Footnote*</p>
                </div> */}
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
          <div className="col-12">
              <div className='famous-card position-relative'>
              <img className='img-fluid' src={mainBannerImg4} alt='famous'/>
                {/* <div className='famous-content position-absolute'>
                    <h5 className='text-dark' >Home Speakers</h5>
                    <h6 className='text-dark' >Room-filling sound</h6>
                    <p className='text-dark'>From $699 or $116.58/mo. for 12 mo.*</p>
                </div> */}
              </div>
            </div>
          </Carousel.Item>
          </Carousel>
            
            
            
            
          </div>
      </Container>

      <Container class1='special-wrapper py-5 home-wrapper-2 d-lg-block d-none'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>Special Monthly Offers</h3>
            </div>
          </div>
          <div className='row'>
            {Array.isArray(productState) && productState?.map((item, index) => {
              let hasSpecialProduct = false;
              if (item?.tags === 'special') {
                hasSpecialProduct = true;
              }
              return hasSpecialProduct ? <SpecialProduct key={index} id={item?._id} title={item?.title} brand={item?.brand} price={item?.price} stock={item?.stock} totalrating={parseFloat(item?.totalrating, 10)} images={item?.images[0].url} /> : null;
            })}
            {(!productState || productState.length === 0) && <p>No Products Available In This Category</p>}
          </div>
      </Container>

      {/* <Container class1='special-wrapper py-5 home-wrapper-2 d-lg-none'>
          <div className='row'>
            <div className='col-12'>
              <div className='d-flex justify-content-between align-items-center'>
                <div>
                  <h3 className='section-heading'>Special Monthly Offers</h3>
                </div>
                <div className='custom-controls'>
                <button onClick={handlePrev} className='custom-prev border-0'>
                  {'<'}
                </button>
                <button onClick={handleNext} className='custom-next border-0'>
                  {'>'}
                </button>
              </div>
              </div>
              
            </div>
          </div>
          <div className='row'>
            <div className='col-12 d-flex justify-content-between align-items-center'>
            <Carousel controls={false} interval={null} indicators={false}>
        {specialChunks.map((chunk, index) => (
          <Carousel.Item key={index}>
            <div className='row'>
            {chunk.map((item, i) => {
                let hasSpecialProduct = false;
                if (item?.tags === 'special') {
                  hasSpecialProduct = true;
                }
                return hasSpecialProduct ? (
                  <div className='col-12' key={i}>
                    <SpecialProduct
                      id={item?._id}
                      title={item?.title}
                      brand={item?.brand}
                      price={item?.price}
                      stock={item?.stock}
                      totalrating={parseFloat(item?.totalrating, 10)}
                      images={item?.images[0]?.url}
                    />
                  </div>
                ) : null;
              })}
            </div>
              
            
          </Carousel.Item>
        ))}
      </Carousel>
            {(!productState || productState.length === 0) && <p>No Products Available In This Category</p>}
            </div>
            
            
          </div>
      </Container> */}

      <Container class1='popular-wrapper py-5 home-wrapper-2 d-lg-block d-none'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>
              Our Popular Products
              </h3>
            </div>
          </div>
            <div className='row'>
            {Array.isArray(productState) && productState.map((item, index) => {
              const isWishlist = wishlist.some(itemId => itemId === item._id);
              let hasPopularProduct = false;
              if (item?.tags === 'popular') {
                hasPopularProduct = true;
              }

                return( hasPopularProduct ? 
                    <div
                    key={index}
                    className="col-3 prod-card">
        {/**REVISIT */}
        <div
        className='product-card position-relative'>
            <div className='wishlist-icon position-absolute'>
            <button className='border-0 bg-transparent' onClick={(e) => toggleWishlist(item?._id)}>
                {isWishlist ? <FcLike /> : <img src={wishListImg} alt="wish list" />}
                <span className="like-text">Add to wishlist</span>
                </button>
            </div>
            <div className='product-image' onClick={()=>{navigate("/product/"+item?._id); window.scrollTo({ top: 0, behavior: 'smooth' });}} src={viewImg} alt='view'>
              <img className='img-fluid mx-auto' src={item?.images[0]?.url} alt='product'/>
              <img className='img-fluid mx-auto' src={item?.images[1]?.url} alt='product'/>
            </div>
            <div className='product-details mt-3' onClick={()=>{navigate("/product/"+item?._id); window.scrollTo({ top: 0, behavior: 'smooth' });}} src={viewImg} alt='view'>
                <h6 className='brand'>{item?.brand}</h6>
                <h5 className='product-title'>
                    {item?.title}
                </h5>
                <ReactStars
                    count={5}
                    size={24}
                    value={parseFloat(item?.totalrating)}
                    edit={false}
                    activeColor="#ffd700"
                />
                
                <p className="price">KSh. {item?.price}</p>
            </div>
            <div className='action-bar position-absolute'>
                <div className='d-flex flex-column gap-15'>
                    {/* <button className='border-0 bg-transparent'>
                        <img src={prodCompareImg} alt='compare'/>
                    </button> */}
                    <button onClick={() => addToCart(item?._id, item?.price)} className='border-0 bg-transparent cart-img'>
                        <img src={addCartImg} alt='add cart'/>
                        <span className="cart-text">Add to cart</span>
                    </button>
                    <button onClick={()=>{navigate("/product/"+item?._id); window.scrollTo({ top: 0, behavior: 'smooth' });}} className='border-0 bg-transparent view-img'>
                        <img src={viewImg} alt='view'/>
                        <span className="view-text">Quick view</span>
                    </button>
                </div>
            </div>
        </div>
    </div> : null
                )
            })
        }
        {(!productState || productState.length === 0) && <p>No Products Available In This Category</p>}

        

    </div>
            
            
          
      </Container>

      <Container class1='popular-wrapper py-5 home-wrapper-2 d-lg-none'>
      <div className='row'>
            <div className='col-12'>
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                <h3 className='section-heading'>
                Our Popular Products
                </h3>
              </div>
              <div className='custom-controls'>
                <button onClick={handlePrev} className='custom-prev border-0'>
                  {'<'}
                </button>
                <button onClick={handleNext} className='custom-next border-0'>
                  {'>'}
                </button>
          </div>
            </div>
            </div>
          </div>
          <div className='row'>
        <div className='col-12 d-flex justify-content-between align-items-center'>
          <Carousel controls={false} indicators={false} interval={null} ref={carouselRef}>
            {popularChunks.map((chunk, index) => (
              <Carousel.Item key={index}>
                <div className='row'>
                  {chunk.map((item, i) => {
                    const isWishlist = wishlist.some(itemId => itemId === item._id);
                    return (
                      <div key={i} className='col-6'>
                        <div className='product-card position-relative'>
                          <div className='wishlist-icon position-absolute'>
                            <button className='border-0 bg-transparent' onClick={() => toggleWishlist(item?._id)}>
                              {isWishlist ? <FcLike /> : <img src={wishListImg} alt="wish list" />}
                              <span className="like-text">Add to wishlist</span>
                            </button>
                          </div>
                          <div className='product-image' onClick={() => { navigate("/product/" + item?._id); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>
                            <img className='img-fluid mx-auto' src={item?.images[0]?.url} alt='product' />
                            <img className='img-fluid mx-auto' src={item?.images[1]?.url} alt='product' />
                          </div>
                          <div className='product-details mt-3' onClick={() => { navigate("/product/" + item?._id); window.scrollTo({ top: 0, behavior: 'smooth' }); }}>
                            <h6 className='brand'>{item?.brand}</h6>
                            <h5 className='product-title'>
                              {item?.title}
                            </h5>
                            <ReactStars
                              count={5}
                              size={24}
                              value={parseFloat(item?.totalrating)}
                              edit={false}
                              activeColor="#ffd700"
                            />
                            <p className="price">KSh. {item?.price}</p>
                          </div>
                          <div className='action-bar position-absolute'>
                            <div className='d-flex flex-column gap-15'>
                              <button onClick={() => addToCart(item?._id, item?.price)} className='border-0 bg-transparent cart-img'>
                                <img src={addCartImg} alt='add cart' />
                                <span className="cart-text">Add to cart</span>
                              </button>
                              <button className='border-0 bg-transparent view-img'>
                                <img onClick={() => { navigate("/product/" + item?._id); window.scrollTo({ top: 0, behavior: 'smooth' }); }} src={viewImg} alt='view' />
                                <span className="view-text">Quick view</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
        {(!productState || productState.length === 0) && <p>No Products Available In This Category</p>}
      </div>
            
            
          
      </Container>

      <Container class1='marquee-wrapper py-5 d-lg-block d-none'>
          <div className='row'>
            <div className="col-12">
              <div className='marquee-inner-wrapper card-wrapper'>
              <Marquee className='d-flex'>
                <div className='mx-4 w-25'>
                  <img src={brandImg1} alt='brand'/>
                </div>
                <div className='mx-4 w-25'>
                  <img src={brandImg2} alt='brand'/>
                </div>
                <div className='mx-4 w-25'>
                  <img src={brandImg3} alt='brand'/>
                </div>
                <div className='mx-4 w-25'>
                  <img src={brandImg4} alt='brand'/>
                </div>
                <div className='mx-4 w-25'>
                  <img src={brandImg5} alt='brand'/>
                </div>
                <div className='mx-4 w-25'>
                  <img src={brandImg6} alt='brand'/>
                </div>
                <div className='mx-4 w-25'>
                  <img src={brandImg7} alt='brand'/>
                </div>
                <div className='mx-4 w-25'>
                  <img src={brandImg8} alt='brand'/>
                </div>
              </Marquee>
              </div>
            </div>
          </div>
      </Container>
      <Container class1='marquee-wrapper d-lg-none'>
          <div className='row'>
            <div className="col-12">
              <div className='marquee-inner-wrapper card-wrapper'>
              <Marquee className='d-flex'>
                <div className='mx-4 w-25'>
                  <img src={brandImg1} alt='brand'/>
                </div>
                <div className='mx-4 w-25'>
                  <img src={brandImg2} alt='brand'/>
                </div>
                <div className='mx-4 w-25'>
                  <img src={brandImg3} alt='brand'/>
                </div>
                <div className='mx-4 w-25'>
                  <img src={brandImg4} alt='brand'/>
                </div>
                <div className='mx-4 w-25'>
                  <img src={brandImg5} alt='brand'/>
                </div>
                <div className='mx-4 w-25'>
                  <img src={brandImg6} alt='brand'/>
                </div>
                <div className='mx-4 w-25'>
                  <img src={brandImg7} alt='brand'/>
                </div>
                <div className='mx-4 w-25'>
                  <img src={brandImg8} alt='brand'/>
                </div>
              </Marquee>
              </div>
            </div>
          </div>
      </Container>

      

      {/* <Container class1='blog-wrapper py-5 home-wrapper-2'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='section-heading'>
              Latest Blogs
              </h3>
            </div>
            <BlogCard />
            <BlogCard />
            <BlogCard />
            <BlogCard />
          </div>
      </Container> */}
    </>
  )
}

export default Home
