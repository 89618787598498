import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import Container from './Container';
import {BsSearch} from 'react-icons/bs';
// import compareImg from '../assets/images/compare.svg';
import wishListImg from '../assets/images/wishlist.svg';
import userImg from '../assets/images/user.svg';
import cartImg from '../assets/images/cart.svg';
import menuImg from '../assets/images/menu.svg';
import logo from '../assets/images/logo_1.png'  
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
// import { getCartDetails } from '../features/cart/cartSlice';
import { getAllCategories } from '../features/categories/categorySlice';
import { IoIosMenu } from "react-icons/io";
import SideBar from './SideBar';


// import { getCartDetails } from '../features/user/userSlice';


const Header = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
    // const [total, setTotal] = useState(null);
    const cartState = useSelector(state=>state?.cart?.cartProducts);
    const authState = useSelector((state) => state?.auth);
    const categoryState = useSelector((state)=> state?.category?.category);
    
    
    // const productState = useSelector(state=>state?.product?.product);
    // const [productOpt, setProductOpt] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    

    const totalQuantity = cartState ? cartState.reduce((total, item) => total + item.quantity, 0) : 0;

    useEffect(() => {
      dispatch(getAllCategories());
    }, [dispatch]);
    // const [paginate, setPaginate] = useState(true);

    // useEffect(() => {
    //   dispatch(getCartDetails());
    // }, [dispatch]);

    // useEffect(() => {
    //   let sum = 0;
    //   for (let index = 0; index < cartState?.length; index++) {
    //       sum = sum +(Number(cartState[index].quantity)*cartState[index].price)
    //       setTotal(sum)
    //   }
    // }, [cartState]);


    // useEffect(()=>{
    //   let data = [];
    //   for (let index = 0; index < productState.length; index++) {
    //     const element = productState[index];
    //     data.push({id:index, prod:element?._id, name:element?.title})
    //   }

    //   setProductOpt(data)
    // }, [productState])


    const handleSearch = () => {
      navigate(`/product?search=${searchTerm}`);
      
    };
  
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        handleSearch();
      }
    };
  
    const handleInputChange = (value) => {
      setSearchTerm(value);;
    };

    
    const handleLogout = () => {
      localStorage.clear();
      window.location.reload();
    }
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    }


  return (
    <>
      <header className='header-top-strip py-3'>
        <Container>
          <div className='row'>
                
            <div className="col-6 mb-3 d-lg-block d-none">
              <p className='text-white mb-0'>Same Day In-store Pickup or Express Delivery</p>
            </div>
            <div className="col-6 mb-3">
              <p className='text-end text-white mb-0'>
              Hotline: 
              <a className='text-white mx-2 mb-0' href='tel:+254783300330'>0783300330</a>
              </p>
            </div>
          </div>
        </Container>
      </header>

      <header className='header-upper py-3'>
      <Container>
      <div className='row align-items-center justify-content-between'>
        {/* Menu button for mobile screens */}
        <div className="col-auto d-lg-none" onClick={props.onClick}>
          <IoIosMenu style={{ fontSize: '25px' }} />
        </div>
            <div className="col-auto">
              <h2>
                <Link className='logo text-black' to="/">
                  <img className='logo' src={logo} alt="logo" />
                </Link>
              </h2>
            </div>
            <div className="col d-lg-block d-none">
              <div className="input-group">
                <Typeahead
                  id="pagination-example"
                  labelKey={"name"}
                  options={[]}
                  minLength={100}
                  placeholder="Search for products here..."
                  value={searchTerm}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  onInputChange={handleInputChange}
                />
                <span
                  className="input-group-text p-3"
                  id="basic-addon2"
                  onClick={handleSearch}
                >
                  <BsSearch className='fs-6' />
                </span>
              </div>
            </div>
            <div className="col-auto">
              <div className='header-upper-links d-flex align-items-center justify-content-end'>
              <div>
                  <div className='d-flex align-items-center gap-10 text-white mx-3 '
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <img src={userImg} alt='user' />
                    <p className='mb-0 text-black d-lg-block d-none'>
                      {authState.user === null ? (
                        <>
                          Login <br /> Sign Up
                        </>
                      ) : (
                        <>
                        Welcome <br /> {authState?.user?.username}
                        </>
                      )}
                    </p>
                  </div>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    {authState?.user === null ? (
                      <>
                        <Link to="/login" className="dropdown-item">Login</Link>
                        <Link to="/signup" className="dropdown-item">Sign Up</Link>
                      </>
                    ) : (
                      <>
                        <Link to="/my-profile" className="dropdown-item">View Profile</Link>
                        <Link className="dropdown-item" onClick={handleLogout}>Logout</Link>
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <Link to='/wishlist'
                    className='d-flex align-items-center gap-10 text-white mx-3'>
                    <img src={wishListImg} alt='wishlist' />
                    <p className='mb-0 text-black d-lg-block d-none'>
                      View <br /> Wishlist
                    </p>
                  </Link>
                </div>
                <div>
                  <Link to='cart'
                    className='d-flex align-items-center gap-10 text-white mx-3'>
                    <img src={cartImg} alt='cart' />
                    <div className='d-flex flex-column'>
                      <span className='badge bg-white text-black d-lg-block d-none border-bottom' style={{ fontSize: '15px' }}>{totalQuantity}</span>
                      {/* <span className='badge bg-white text-black d-lg-block d-none' style={{ fontSize: '13px' }}>{total}</span> */}
                    </div>
                  </Link>
                </div>
                
              </div>
            </div>
          </div>
      </Container>
      </header>


      <header className='header-bottom py-3'>
      <Container>
      <div className='row'>
            <div className='col-12'>
              <div className='menu-bottom d-flex align-items-center gap-30 mt-2'>
                <div className="input-group d-lg-none">
                  <Typeahead
                    id="pagination-example"
                    labelKey={"name"}
                    options={[]}
                    minLength={100}
                    placeholder="Search for products..."
                    value={searchTerm}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    onInputChange={handleInputChange}
                  />
                  <span
                    className="input-group-text p-3"
                    id="basic-addon2"
                    onClick={handleSearch}
                  >
                    <BsSearch className='fs-6' />
                  </span>
                </div>
                <div className='menu-items d-lg-block d-none'>
                  <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle bg-transparent border-0 gap-15 d-flex align-items-center" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      <img src={menuImg} alt='menu'/>
                      <span className='me-5 d-inline-block'>
                      Product Categories
                      </span>
                    </button>
                    <ul  className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    {
                      categoryState && categoryState.map((item)=>{
                        return (
                          
                            <li key={item._id}>
                              <Link className="dropdown-item text-white" to={`/product?category=${item?.title}`}>
                              {item?.title}
                              </Link>
                            </li>
                          
                        )
                      })
                    }
                    </ul>
                    {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li>
                      <Link className="dropdown-item text-white" to="/product?category=Laptops">
                      Laptops
                      </Link>
                      </li>
                      <li>
                      <Link className="dropdown-item text-white" to="/product?category=Monitors">
                      Monitors
                      </Link>
                      </li>
                      <li>
                      <Link className="dropdown-item text-white" to="/product?category=Desktops">
                      Desktops
                      </Link>
                      </li>
                      <li>
                      <Link className="dropdown-item text-white" to="/product?category=Apple Products">
                      Apple Products
                      </Link>
                      </li>
                      <li>
                      <Link className="dropdown-item text-white" to="/product?category=Printers And Scanners">
                      Printers And Scanners
                      </Link>
                      </li>
                      <li>
                      <Link className="dropdown-item text-white" to="/product?category=CCTV Cameras">
                      CCTV Cameras
                      </Link>
                      </li>
                      <li>
                      <Link className="dropdown-item text-white" to="/product?category=Networking">
                      Networking
                      </Link>
                      </li>
                      <li>
                      <Link className="dropdown-item text-white" to="/product?category=Accessories">
                      Accessories
                      </Link>
                      </li>
                      <li>
                      <Link className="dropdown-item text-white" to="/services">
                      IT Solutions
                      </Link>
                      </li>
                    </ul> */}
                  </div>
                </div>
                <div className='menu-links d-lg-block d-none'>
                <div className='d-flex align-items-center gap-15'>
                  <NavLink className={'menu-link'} to="/">Home</NavLink>
                  <NavLink className={'menu-link'} to="/product">Our Store</NavLink>
                  <NavLink className={'menu-link'} to="/services">IT Solutions</NavLink>
                  <NavLink className={'menu-link'} to="/contact">Contact Us</NavLink>
                  <NavLink className={'menu-link'} to="/my-orders">My Orders</NavLink>
                </div>
                </div>
              </div>
            </div>
          </div>
      </Container>
      </header>
      {isMenuOpen && <SideBar isOpen={isMenuOpen} toggleSidebar={toggleMenu} />}
    </>
  );
};
export default Header;