import axios from "axios";
import { base_url } from "../../utils/axiosConfig";



const getSingleCategory = async(id)=>{
    const response = await axios.get(`${base_url}category/${id}`);
    if (response.data) {
        return response.data;
    }
};
const getAllCategory = async()=>{
    const response = await axios.get(`${base_url}category/`);
    if (response.data) {
        return response.data;
    }
};

export const categoryService={
   getSingleCategory,
   getAllCategory
}