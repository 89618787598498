import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import SideBar from './SideBar'
import Footer from './Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Layout = () => {
  const [sidebarOpen, setSideBarOpen] = useState(false);

  const handleViewSidebar = () => {
    setSideBarOpen(!sidebarOpen);
  };
  const handleLinkClick = (event) => {
    event.stopPropagation(); // Stop event propagation
    setSideBarOpen(false); // Close the sidebar when a link is clicked
  };


  return (
    <>
        <Header onClick={handleViewSidebar} />
        {sidebarOpen && <SideBar isOpen={sidebarOpen} toggleSidebar={handleViewSidebar} onClickLink={handleLinkClick} />}
        <Outlet />
        <Footer />
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
    </>
  )
}

export default Layout