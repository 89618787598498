import axios from "axios";
import { base_url } from "../../utils/axiosConfig";

const addToWishlist = async(prodId)=>{
    const response = await axios.put(`${base_url}product/wishlist`, { prodId }, {
        withCredentials: true
    });
    if (response.data) {
        return response.data;
    }
}

const getUserWishlist = async()=>{
    const response = await axios.get(`${base_url}user/wishlist`, {
        withCredentials: true
    });
    if (response.data) {
        return response.data;
    }
};

export const wishlistService = {
    addToWishlist,
    getUserWishlist
}