import axios from "axios";
import { base_url } from "../../utils/axiosConfig";

const createOrder = async (orderDetail) => {
    const response = await axios.post(`${base_url}user/cart/create-order`, orderDetail, {
      withCredentials: true
  });
    if (response.data) {
      return response.data;
    }
  };

const getUserOrders = async()=>{

const response = await axios.get(`${base_url}user/get-my-orders`, {
    withCredentials: true
});

if (response.data) {
    return response.data;
}
};

export const orderService = {
    createOrder,
    getUserOrders
}