import React from 'react'
import Container from '../components/Container';
// import BreadCrumb from '../components/BreadCrumb';
// import Meta from '../components/Meta';


const PrivacyPolicy = () => {
  return (
    <>
        {/* <Meta title={'Privacy Policy'}/>
        <BreadCrumb title="Privacy Policy"
        /> */}

<Container class1='policy-wrapper py-5 home-wrapper-2'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='policy'>
                        <h1 className='mb-3'>Privacy Policy</h1>
                          <p className='mt-2'>Welcome to Silicon Savannah Technologies' website. Your privacy is important to us, and we are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide when using our website or services.</p>

                          <p>When you visit our website, we may automatically collect certain information, such as your IP address, browser type, and device identifiers. We use this information to improve our website's functionality and provide a better user experience.

                          If you choose to make a purchase or request services through our website, we may collect additional personal information, such as your name, email address, shipping address, and payment details. Rest assured that we only use this information to fulfill your orders and provide the requested services.</p>

                          <p>We understand the importance of data security, and we take appropriate measures to safeguard your personal information from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure.

                          We may share your personal information with trusted third-party service providers who assist us in operating our website, processing payments, or delivering services. These service providers are contractually obligated to protect your information and use it only for the purposes specified by us.</p>

                          <p>Our website may also use cookies to enhance your browsing experience and collect information about how you interact with our site. You have the option to disable cookies in your browser settings, although this may affect certain functionalities of the website.

                          Please be aware that our website may contain links to third-party websites or services that are not operated by us. We have no control over the content, privacy policies, or practices of these websites and encourage you to review their policies before providing any personal information.</p>

                          <p>Our website is not intended for children under the age of 13, and we do not knowingly collect personal information from children. If you believe that we have inadvertently collected information from a child, please contact us immediately.

                          We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting on our website. By continuing to use our website after any revisions, you agree to be bound by the updated Privacy Policy.</p>

                          <p>If you have any questions or concerns regarding this Privacy Policy or our data practices, please contact us at <a 
                            className='text-dark' style={{textDecoration: 'underline'}}
                            href='mailto:siliconsavannahtechnologies@gmail.com'>
                            siliconsavannahtechnologies@gmail.com
                            </a>.

                          Thank you for entrusting Silicon Savannah Technologies with your personal information. We are dedicated to maintaining your privacy and providing a safe online environment.</p>
                        </div>
                    </div>
                </div>
        </Container>
    </>
  )
}

export default PrivacyPolicy