import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { wishlistService } from './wishlistService';

export const addToWishlist = createAsyncThunk("product/wishlist", async (prodId, thunkAPI)=>{
    try{
        return await wishlistService.addToWishlist(prodId);
    } catch(error) {
        return thunkAPI.rejectWithValue(error)
    }
});
export const getUserProductWishlist = createAsyncThunk(
    "user/wishlist",
    async (thunkAPI) => {
        try{
            return await wishlistService.getUserWishlist();
        } catch(error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
);

// const getWishlistfromLocalStorage = localStorage.getItem("wishlist")
//   ? JSON.parse(localStorage.getItem("wishlist"))
//   : [];

const wishlistState = {
    wishlist:"",
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:"",
    
}

export const wishlistSlice = createSlice({
    name:"wishlist",
    initialState:wishlistState,
    reducers:{},
    extraReducers:(builder)=>{
        builder
        .addCase(addToWishlist.pending, (state)=>{
            state.isLoading=true;
        })
        .addCase(addToWishlist.fulfilled,(state,action)=> {
            state.isLoading= false;
            state.isSuccess= true;
            state.isError= false;
            state.addToWishlist= action.payload;
            state.message= "Added to wishlist!"
            // if (state.isSuccess === true) {
            //     localStorage.setItem('wishlist', JSON.stringify(action.payload.wishlist));
            // }
        })
        .addCase(addToWishlist.rejected, (state,action)=>{
            state.isLoading= false;
            state.isError= true;
            state.isSuccess= false;
            state.message= action.error
        })
        .addCase(getUserProductWishlist.pending, (state)=>{
            state.isLoading=true;
        }).addCase(getUserProductWishlist.fulfilled, (state, action)=> {
            state.isError=false;
            state.isSuccess=true;
            state.isLoading=false;
            state.wishlist=action.payload;
        }).addCase(getUserProductWishlist.rejected, (state, action)=>{
            state.isError=true;
            state.isSuccess=false;
            state.isLoading=false;
            state.message=action.error;
        })
   } 
});

export default wishlistSlice.reducer;