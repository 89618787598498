import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { IoIosClose } from 'react-icons/io';

const SideBar = (props) => {
  const [activeTab, setActiveTab] = useState('Categories');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const sidebarClass = props.isOpen ? "sidebar open" : "sidebar";

  return (
    <div className={sidebarClass}>
        <div className='close-sidebar d-flex align-items-end justify-content-end px-1'><button className="close-btn" onClick={props.toggleSidebar}>
          <IoIosClose style={{ fontSize: '30px' }} />
        </button></div>
        <div className="sidebar-header">
  <div className="sidebar-tabs-container d-flex justify-content-center py-3">
    <div
      className={`sidebar-tab ${activeTab === 'Categories' ? 'active' : ''}`}
      onClick={() => handleTabClick('Categories')}
    >
      Categories
    </div>
    <div
      className={`sidebar-tab ${activeTab === 'Menu' ? 'active' : ''}`}
      onClick={() => handleTabClick('Menu')}
    >
      Menu
    </div>
  </div>
</div>

      <div className="sidebar-content mt-3">
        {activeTab === 'Categories' && (
          <ul className="sidebar-list">
            <li>
            <NavLink to="/product?category=Laptops" className="sidebar-link" activeClassName="active" onClick={(e) => props.onClickLink(e)}>
            Laptops
            </NavLink>
            </li>
            <li>
              <NavLink tto="/product?category=Desktops" className="sidebar-link" activeClassName="active" onClick={(e) => props.onClickLink(e)}>
                Desktops
              </NavLink>
            </li>
            <li>
              <NavLink to="/product?category=Apple Products" className="sidebar-link" activeClassName="active" onClick={(e) => props.onClickLink(e)}>
                Apple Products
              </NavLink>
            </li>
            <li>
              <NavLink to="/product?category=Printers And Scanners" className="sidebar-link" activeClassName="active" onClick={(e) => props.onClickLink(e)}>
                Printers And Scanners
              </NavLink>
            </li>
            <li>
              <NavLink to="/product?category=Monitors" className="sidebar-link" activeClassName="active" onClick={(e) => props.onClickLink(e)}>
                Monitors
              </NavLink>
            </li>
            <li>
              <NavLink to="/product?category=CCTV Cameras" className="sidebar-link" activeClassName="active" onClick={(e) => props.onClickLink(e)}>
                CCTV Cameras
              </NavLink>
            </li>
            <li>
              <NavLink to="/product?category=Networking" className="sidebar-link" activeClassName="active" onClick={(e) => props.onClickLink(e)}>
                Networking
              </NavLink>
            </li>
            <li>
              <NavLink to="/product?category=Accessories" className="sidebar-link" activeClassName="active" onClick={(e) => props.onClickLink(e)}>
                Accessories
              </NavLink>
            </li>
          </ul>
        )}
        {activeTab === 'Menu' && (
          <ul className="sidebar-list">
          <li>
              <NavLink to="/" className="sidebar-link" activeClassName="active" onClick={(e) => props.onClickLink(e)} exact>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/product" className="sidebar-link" activeClassName="active" onClick={(e) => props.onClickLink(e)}>
                Our Store
              </NavLink>
            </li>
            <li>
              <NavLink to="/services" className="sidebar-link" activeClassName="active" onClick={(e) => props.onClickLink(e)}>
                IT Solutions
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" className="sidebar-link" activeClassName="active" onClick={(e) => props.onClickLink(e)}>
                Contact Us
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/my-orders" className="sidebar-link" activeClassName="active" onClick={(e) => props.onClickLink(e)}>
                My Orders
              </NavLink>
            </li> */}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SideBar;
