import axios from "axios";
import { base_url } from "../../utils/axiosConfig";

const addToCart = async (cartData) => {
    try {
        const response = await axios.post(`${base_url}user/cart`, cartData, {
            withCredentials: true
        });

        return response.data;
    } catch (error) {
        console.error('Error adding item to cart:', error);
        throw error;
    }
};

const getCart = async () => {
    try {
        const response = await axios.get(`${base_url}user/cart`, {
            withCredentials: true
        });

        // Process the cart data
        return response.data;
    } catch (error) {
        console.error('Error fetching cart:', error);
        throw error;
    }
};

const updateProdFromCart = async(cartDetail)=>{
    
    const response = await axios.patch(`${base_url}user/update-product-cart/${cartDetail.cartItemId}`, { newQuantity: cartDetail.quantity }, {
        withCredentials: true
    });
    
    if (response.data) {
        return response.data;
    }
};

const removeProdFromCart = async(cartItemId)=>{
    const response = await axios.delete(`${base_url}user/delete-product-cart/${cartItemId}`, {
        withCredentials: true
    });
    
    if (response.data) {
        return response.data;
    }
};

const emptyCartItems = async () => {
    const response = await axios.delete(`${base_url}user/empty-cart`, {
        withCredentials: true
    });
    if (response.data) {
      return response.data;
    }
  };

export const cartService = {
    addToCart,
    getCart,
    updateProdFromCart,
    removeProdFromCart,
    emptyCartItems
}