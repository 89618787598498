import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToWishlist, getUserProductWishlist } from '../features/wishlist/wishlistSlice';
import { addProdToCart, getCartDetails, updateCartProd } from '../features/cart/cartSlice';
import ReactStars from 'react-rating-stars-component';
import viewImg from '../assets/images/view.svg';
import addCartImg from '../assets/images/add-cart.svg';
import wishListImg from '../assets/images/wish.svg';
import { FcLike } from 'react-icons/fc';
import { toast } from "react-toastify";


const ProductCard = (props) => {
  const navigate = useNavigate();
  let location = useLocation();
  const { grid, data } = props;
  const dispatch = useDispatch();
  

// Fetch wishlist state
const wishlistState = useSelector(state => state?.wishlist?.wishlist[0]?.wishlist);

// Initialize wishlist state after wishlistState is fetched
useEffect(() => {
  if (wishlistState) {
    setWishlist(wishlistState.map(item => item._id));
  }
}, [wishlistState]);

// State to hold wishlist
const [wishlist, setWishlist] = useState([]);

useEffect(() => {
  dispatch(getCartDetails());
  dispatch(getUserProductWishlist());
}, [dispatch]);

// Toggle wishlist
const toggleWishlist = (id) => {
  const existingWishItem = wishlist.includes(id);

  if (existingWishItem) {
    dispatch(addToWishlist(id)).then(() => dispatch(getUserProductWishlist()));
    setWishlist(wishlist.filter((itemId) => itemId !== id));
    toast.info("Removed from wishlist");
  } else {
    dispatch(addToWishlist(id)).then(() => dispatch(getUserProductWishlist()));
    setWishlist([...wishlist, id]);
    toast.success("Added to wishlist successfully");
  }
};



// check and add product to cart
const userCartState = useSelector(state=>state?.cart?.cartProducts);


const addToCart = async (productId, price) => {
  const existingItem = userCartState && userCartState.find((item) => item.productId._id === productId);
  if (existingItem) {
    const newQuantity = existingItem.quantity + 1;
    await dispatch(updateCartProd({ cartItemId: existingItem._id, quantity: newQuantity }));
  } else {
    await dispatch(addProdToCart({ productId, quantity: 1, price }));
  }

  await dispatch(getCartDetails());
  navigate('/cart');
};


  
 
  return (
    <>
      {Array.isArray(data) &&
        data?.map((item, index) => {
          const isWishlist = wishlist.some(itemId => itemId === item._id);
          return (
            <div key={index} className={`${location.pathname === '/product' ? `gr-${grid}` : 'col-3'}`}>
              <div className="product-card position-relative">
                <div className="wishlist-icon position-absolute">
                  <button className="border-0 bg-transparent" onClick={(e) => toggleWishlist(item?._id)}>
                    {isWishlist ? <FcLike /> : <img src={wishListImg} alt="wish list" />}
                    <span className="like-text">Add to wishlist</span>
                  </button>
                </div>
                <div className="product-image" onClick={() => navigate(`/product/${item?._id}`)} src={viewImg} alt="view">
                  <img className="img-fluid mx-auto" src={item?.images[0]?.url} alt="product" />
                  <img className="img-fluid mx-auto" src={item?.images[1]?.url} alt="product" />
                </div>
                <div className="product-details mt-3" onClick={() => navigate(`/product/${item?._id}`)} src={viewImg} alt="view">
                  <h6 className="brand">{item?.brand}</h6>
                  <h5 className="product-title">{item?.title}</h5>
                  <ReactStars count={5} size={24} value={parseFloat(item?.totalrating)} edit={false} activeColor="#ffd700" />
                  <p className={`description ${grid === 12 ? 'd-block' : 'd-none'}`} dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                  <p className="price">KSh. {item?.price}</p>
                </div>
                <div className="action-bar position-absolute">
                  <div className="d-flex flex-column gap-15">
                    <button onClick={() => addToCart(item?._id, item?.price)} className="border-0 bg-transparent cart-img">
                      <img src={addCartImg} alt="add cart" />
                      <span className="cart-text">Add to cart</span>
                    </button>
                    <button onClick={() => navigate(`/product/${item?._id}`)} className="border-0 bg-transparent view-img">
                      <img src={viewImg} alt="view" />
                      <span className="view-text">Quick view</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
          );
        })}
    </>
  );
};

export default ProductCard;
