import React from 'react'
import { Link } from 'react-router-dom';
import Container from '../components/Container';
// import BreadCrumb from '../components/BreadCrumb';
// import Meta from '../components/Meta';

const TermsOfService = () => {
  return (
    <>
        {/* <Meta title={'Terms Of Service'}/>
        <BreadCrumb title="Terms Of Service"
        /> */}

<Container class1='policy-wrapper py-5 home-wrapper-2'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='policy'>
                          <h1 className='mb-3'>Terms of Service</h1>
                          <p className='mt-2'>Welcome to Silicon Savannah Technologies, your one-stop destination for all your laptop and computer needs. By accessing or using any part of our website, making a purchase, or availing of our services, you agree to be bound by the following Terms of Service. We offer a wide range of laptops, desktop computers, peripherals, and related services such as repairs, upgrades, and maintenance. All orders are subject to availability and acceptance, and payment must be made in full at the time of purchase unless otherwise agreed upon. We accept various payment methods, including credit/debit cards, cash, and online payment gateways.</p>
                          <p className='mt-2'>We strive to ensure timely delivery of all orders; however, delivery times may vary depending on factors beyond our control. Shipping costs and delivery estimates will be provided at the time of purchase. We offer a hassle-free return policy, allowing returns within 10 days for an exchange, provided the item is in its original condition and packaging.</p>
                          <p className='mt-2'>All products sold by Silicon Savannah Technologies are covered by a manufacturer's warranty, with terms and duration varying depending on the product and manufacturer. Expert repair and maintenance services for laptops and desktop computers are provided by trained technicians using genuine parts whenever possible.</p>
                          <p className='mt-2'>All content displayed on our website, including logos, trademarks, images, and text, is the property of Silicon Savannah Technologies or its respective owners and is protected by copyright and other intellectual property laws. We are committed to protecting your privacy and personal information, as outlined in our <Link to='/privacy-policy'>Privacy Policy.</Link></p>
                          <p className='mt-2'>In no event shall Silicon Savannah Technologies or its affiliates be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of our products or services. You agree to indemnify and hold Silicon Savannah Technologies, its officers, directors, employees, and agents harmless from any claims, losses, damages, liabilities, and expenses (including legal fees) arising out of or related to your use of our products or services.</p>
                          <p className='mt-2'>These Terms of Service shall be governed by and construed in accordance with the laws of Kenya without regard to its conflict of law provisions. Silicon Savannah Technologies reserves the right to update or modify these Terms of Service at any time without prior notice. By continuing to use our website or services after any such changes, you agree to be bound by the revised Terms of Service.</p>
                          <p className='mt-2'>If you have any questions or concerns regarding these Terms of Service, please contact us at 0783300330. Thank you for choosing Silicon Savannah Technologies for your laptop and computer needs!</p>
                        </div>
                    </div>
                </div>
        </Container>
    </>
  )
}

export default TermsOfService