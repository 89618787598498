import React from 'react'
import Container from '../components/Container';
import { BsCheckCircle } from "react-icons/bs";

const ConfirmOrder = () => {
  return (
    <>
      <Container class1='checkout-wrapper py-5 home-wrapper-2'>
        <div className="row">
          <div className='col-12 text-center'>
            <BsCheckCircle className='text-success' style={{ fontSize: '4rem' }} />
            <h2 className='mt-4'>Order Confirmed</h2>
            <p className='fs-5'>Thank you for placing your order with us.</p>
            <p className='fs-5'>Our sales team will get in touch with you shortly.</p>
          </div>
        </div>
      </Container>
    </>
  )
}

export default ConfirmOrder;