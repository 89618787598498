import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { authService } from './userService';

export const registerUser = createAsyncThunk("auth/register", async (data, thunkAPI)=>{
    try{
        return await authService.register(data);
         
    } catch(error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const verifyUser = createAsyncThunk("auth/verify-user", async (data, thunkAPI)=>{
    try{
        return await authService.verifyCode(data);
    } catch(error) {
        return thunkAPI.rejectWithValue(error);
    }
});
export const loginUser = createAsyncThunk("auth/login", async (userData, thunkAPI)=>{
    try{
        return await authService.login(userData)
    } catch(error) {
        return thunkAPI.rejectWithValue(error)
    }
});


export const deleteCartProd = createAsyncThunk(
    "user/cart/product/delete",
    async (cartItemId, thunkAPI) => {
        try{
            return await authService.removeProdFromCart(cartItemId);
        } catch(error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
);

export const updateUserDetails = createAsyncThunk(
    "user/update-details",
    async (userDetail, thunkAPI) => {
        try{
            return await authService.updateUser(userDetail);
        } catch(error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
);
export const forgotPasswordToken = createAsyncThunk(
    "user/forgot-password",
    async (data, thunkAPI) => {
        try{
            return await authService.forgotPassToken(data);
        } catch(error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
);
export const resetPassword = createAsyncThunk(
    "user/reset-password",
    async (data, thunkAPI) => {
        try{
            return await authService.resetPass(data);
        } catch(error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
);


const getCustomerfromLocalStorage = localStorage.getItem("customer")
  ? JSON.parse(localStorage.getItem("customer"))
  : null;

const initialState = {
    user:getCustomerfromLocalStorage,
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:"",
}

export const authSlice = createSlice({
    name:"auth",
    initialState:initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(registerUser.pending, (state)=>{
            state.isLoading=true;
        }).addCase(registerUser.fulfilled, (state, action)=> {
            state.isError=false;
            state.isSuccess=true;
            state.isLoading=false;
            state.createdUser=action.payload;
            if (state.isSuccess === true) {
                toast.info("Check Email for verification code")
            }
        }).addCase(registerUser.rejected, (state, action)=>{
            state.isError=true;
            state.isSuccess=false;
            state.isLoading=false;
            state.message = action.payload.response.data.error || "Something went wrong"; // Display the error message from the backend
            toast.error(state.message);
        })
        .addCase(verifyUser.pending, (state)=>{
            state.isLoading=true;
        }).addCase(verifyUser.fulfilled, (state, action)=> {
            state.isError=false;
            state.isSuccess=true;
            state.isLoading=false;
            state.verifiedUser=action.payload;
            if (state.isSuccess === true) {
                toast.info("Verification complete.  You can now login.")
            }
        }).addCase(verifyUser.rejected, (state, action)=>{
            state.isError=true;
            state.isSuccess=false;
            state.isLoading=false;
            state.message = action.payload.response.data.error || "Something went wrong"; // Display the error message from the backend
            toast.error(state.message);
        })
        
        // loginMutation will be
        .addCase(loginUser.pending, (state)=>{
            state.isLoading=true;
        }).addCase(loginUser.fulfilled, (state, action)=> {
            state.isError=false;
            state.isSuccess=true;
            state.isLoading=false;
            state.user=action.payload;
            if (state.isSuccess === true) {
                localStorage.setItem('token', action.payload.token);
                // window.location.reload();
                toast.info("Logged in succesfully")
            }
        }).addCase(loginUser.rejected, (state, action)=>{
            state.isError=true;
            state.isSuccess=false;
            state.isLoading=false;
            state.user=null;
            state.message = action.payload.response.data.error || "Something went wrong"; // Display the error message from the backend
            toast.error(state.message);
            
        })
        .addCase(updateUserDetails.pending, (state)=>{
            state.isLoading=true;
        }).addCase(updateUserDetails.fulfilled, (state, action)=> {
            state.isError=false;
            state.isSuccess=true;
            state.isLoading=false;
            state.updatedUser=action.payload;
                let currentData = JSON.parse(localStorage.getItem("customer"));
                let newUserData = {
                    _id: currentData._id,
                    token: currentData.token,
                    email: action?.payload?.email,
                    username:action?.payload?.username
                };
                localStorage.setItem("customer", JSON.stringify(newUserData));
                state.user=newUserData;
                toast.success("Profile updated successfully")
            
        }).addCase(updateUserDetails.rejected, (state, action)=>{
            state.isError=true;
            state.isSuccess=false;
            state.isLoading=false;
            state.message=action.error;
            if (state.isError === true) {
                toast.info("Something went wrong")
            }
        })

        .addCase(forgotPasswordToken.pending, (state)=>{
            state.isLoading=true;
        }).addCase(forgotPasswordToken.fulfilled, (state, action)=> {
            state.isError=false;
            state.isSuccess=true;
            state.isLoading=false;
            state.token=action.payload;
            if (state.isSuccess === true) {
                toast.info("Password reset  link has been sent to your email.")
            }
        }).addCase(forgotPasswordToken.rejected, (state, action)=>{
            state.isError=true;
            state.isSuccess=false;
            state.isLoading=false;
            state.message = action.payload.response.data.error || "Something went wrong"; // Display the error message from the backend
            toast.error(state.message);
        })
        .addCase(resetPassword.pending, (state)=>{
            state.isLoading=true;
        }).addCase(resetPassword.fulfilled, (state, action)=> {
            state.isError=false;
            state.isSuccess=true;
            state.isLoading=false;
            state.pass=action.payload;
            if (state.isSuccess === true) {
                toast.info("Password reset  successfully.")
            }
        }).addCase(resetPassword.rejected, (state, action)=>{
            state.isError=true;
            state.isSuccess=false;
            state.isLoading=false;
            state.message=action.error;
            if (state.isError === true) {
                toast.info("Something went wrong")
            }
        })

    }
})

export default authSlice.reducer;

