import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { cartService } from './cartService';

export const addProdToCart = createAsyncThunk(
    "cart/add",
    async (cartData, thunkAPI) => {
        try{
            return await cartService.addToCart(cartData);
        } catch(error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
);

export const getCartDetails = createAsyncThunk(
    "cart/get",
    async (thunkAPI) => {
        try{
            return await cartService.getCart();
        } catch(error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
);

export const updateCartProd = createAsyncThunk(
    "cart/product/update",
    async (cartDetail, thunkAPI) => {
        try{
            return await cartService.updateProdFromCart(cartDetail);
        } catch(error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
);

export const deleteCartProd = createAsyncThunk(
    "user/cart/product/delete",
    async (cartItemId, thunkAPI) => {
        try{
            return await cartService.removeProdFromCart(cartItemId);
        } catch(error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
);

export const emptyCart = createAsyncThunk(
    "user/cart/delete",
    async (thunkAPI) => {
        try{
            return await cartService.emptyCartItems();
        } catch(error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
);


const cartState = {
    cart:"",
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:"",
    
}

export const cartSlice = createSlice({
    name:"cart",
    initialState:cartState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(addProdToCart.pending, (state)=>{
            state.isLoading=true;
        }).addCase(addProdToCart.fulfilled, (state, action)=> {
            state.isError=false;
            state.isSuccess=true;
            state.isLoading=false;
            state.cartProduct=action.payload;
            if (state.isSuccess === true) {
                toast.info("Added to cart successfully")
            }
        }).addCase(addProdToCart.rejected, (state, action)=>{
            state.isError=true;
            state.isSuccess=false;
            state.isLoading=false;
            state.message=action.error;
            if (state.isError === true) {
                toast.error(action.error)
            }
        })
        .addCase(getCartDetails.pending, (state)=>{
            state.isLoading=true;
        }).addCase(getCartDetails.fulfilled, (state, action)=> {
            state.isError=false;
            state.isSuccess=true;
            state.isLoading=false;
            state.cartProducts=action.payload;
        }).addCase(getCartDetails.rejected, (state, action)=>{
            state.isError=true;
            state.isSuccess=false;
            state.isLoading=false;
            state.message=action.error;
        })
        .addCase(updateCartProd.pending, (state)=>{
            state.isLoading=true;
        }).addCase(updateCartProd.fulfilled, (state, action)=> {
            state.isError=false;
            state.isSuccess=true;
            state.isLoading=false;
            state.updateCartProduct=action.payload;
            if (state.isSuccess === true) {
                toast.info("Updated cart quantity successfully")
            }
        }).addCase(updateCartProd.rejected, (state, action)=>{
            state.isError=true;
            state.isSuccess=false;
            state.isLoading=false;
            state.message=action.error;
            if (state.isError === true) {
                toast.error("Something went wrong")
            }
        })
        .addCase(deleteCartProd.pending, (state)=>{
            state.isLoading=true;
        }).addCase(deleteCartProd.fulfilled, (state, action)=> {
            state.isError=false;
            state.isSuccess=true;
            state.isLoading=false;
            state.deleteCartProduct=action.payload;
            if (state.isSuccess === true) {
                toast.info("Removed from cart successfully")
            }
        }).addCase(deleteCartProd.rejected, (state, action)=>{
            state.isError=true;
            state.isSuccess=false;
            state.isLoading=false;
            state.message=action.error;
            if (state.isError === true) {
                toast.error("Something went wrong")
            }
        })
        .addCase(emptyCart.pending, (state)=>{
            state.isLoading=true;
        }).addCase(emptyCart.fulfilled, (state, action)=> {
            state.isError=false;
            state.isSuccess=true;
            state.isLoading=false;
            state.emptiedCart=action.payload;
        }).addCase(emptyCart.rejected, (state, action)=>{
            state.isError=true;
            state.isSuccess=false;
            state.isLoading=false;
            state.message=action.error;
        })
   } 
});

export default cartSlice.reducer;