import React from 'react'
import Container from './Container';
import { Link } from 'react-router-dom';
import { BsTiktok, BsFacebook, BsInstagram } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";



const Footer = () => {
  // scrolling
const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};
  return (
    <>
      {/*<footer className='py-3'>
        <div className="container-xxl">
          <div className='row'>
            <div className='col-5'>
              <div className='footer-top-data d-flex gap-30 align-items-center'>

              </div>
            </div>
          </div>
        </div>
      </footer>*/}
      <footer className='py-4 d-lg-block d-none'>
      <Container>
      <div className='row'>
            <div className='col-4'>
            <h4 className='text-white mb-4'>Contact Us</h4>
            <div>
              <address className='text-white fs-6 footer-links'>
                Accra Trade Centre, 3rd Floor T22 <br /> 
                Moi Avenue, <br /> Nairobi, Kenya <br />
              </address>
              <a 
              className='mt-3 d-block mb-1 text-white footer-links' 
              href='tel:+254768468747'>
              0768468747 / 0789591869
              </a>
              <a 
              className='mt-2 d-block mb-0 text-white footer-links' 
              href='mailto:siliconsavannahtechnologies@gmail.com'>
              siliconsavannahtechnologies@gmail.com
              </a>
            </div>
            <div className='social-icons d-flex align-items-center gap-30 mt-4'>
              <a className='text-white footer-links' 
              href='https://web.facebook.com/profile.php?id=100085536918915&mibextid=JRoKGi&_rdc=1&_rdr' target="_blank" rel="noopener noreferrer" >
                <BsFacebook className='fs-4' />
              </a>
              <a className='text-white footer-links' 
              href='https://www.instagram.com/silicon_savannah_technologies?igsh=MW14cGZwczlkNnIwNQ%3D%3D&utm_source=qr' target="_blank" rel="noopener noreferrer">
                <BsInstagram className='fs-4' />
              </a>
              <a className='text-white footer-links' 
              href='https://www.tiktok.com/@silicon_savannah_tech?_t=8jO04KzlM7Z&_r=1' target="_blank" rel="noopener noreferrer">
                <BsTiktok className='fs-4' />
              </a>
              <a className='text-white footer-links' 
              href='https://x.com/SSavannahTec' target="_blank" rel="noopener noreferrer">
                <FaXTwitter className='fs-4' />
              </a>
            </div>
            </div>
            <div className='col-4' onClick={scrollToTop}>
            <h4 className='text-white mb-4'>Terms</h4>
            <div className='footer-links d-flex flex-column'>
              <Link to='/privacy-policy' className='text-white py-2 mb-1 footer-links'>Privacy Policy</Link>
              <Link to='/warranty-refund' className='text-white py-2 mb-1 footer-links'>Warranty & Refund Policy</Link>
              <Link to='/delivery-policy' className='text-white py-2 mb-1 footer-links'>Delivery Policy</Link>
              <Link to='/terms-service' className='text-white py-2 mb-1 footer-links'>Terms Of Service</Link>
            </div>
            </div>
            {/* <div className='col-3' onClick={scrollToTop}>
            <h4 className='text-white mb-4'>About</h4>
            <div className='footer-links d-flex flex-column'>
              <Link className='text-white py-2 mb-1 footer-links'>About Us</Link>
              <Link className='text-white py-2 mb-1 footer-links'>FAQ</Link>
              <Link className='text-white py-2 mb-1 footer-links'>Customer Reviews</Link>
            </div>
            </div> */}
            <div className='col-4' onClick={scrollToTop}>
            <h4 className='text-white mb-4'>Shop</h4>
            <div className='footer-links d-flex flex-column'>
              <Link className='text-white py-2 mb-1 footer-links' to='/product?category=Laptops'>Laptops</Link>
              <Link className='text-white py-2 mb-1 footer-links' to='/product?category=Apple Products'>Apple Products</Link>
              <Link className='text-white py-2 mb-1 footer-links' to='/product?category=Desktops'>Desktops</Link>
              <Link className='text-white py-2 mb-1 footer-links' to='/product?category=Printers'>Printers</Link>
            </div>
            </div>
          </div>
      </Container>
      </footer>

      <footer className='py-4 d-lg-none'>
  <Container>
    <div className='row'>
      <div className='col-12 mb-3'>
        <div className='d-flex justify-content-between align-items-center'>
          <div>
            <h4 className='text-white'>Contact Us</h4>
          </div>
          <div>
            <button className='btn btn-link text-white' type='button' data-bs-toggle='collapse' data-bs-target='#contactLinks' aria-expanded='false' aria-controls='contactLinks'>
          <FaChevronDown />
          </button>
          </div>
        </div>
        <div className='collapse' id='contactLinks'>
          <address className='text-white fs-6 footer-links mb-1'>
            Accra Trade Centre, 3rd Floor T22
            Moi Avenue, <br /> Nairobi, Kenya <br />
            
          </address>
          <a className='mt-3 d-block mb-1 text-white footer-links' href='tel:+254768468747'>0768468747 / 0789591869</a>
          <a className='mt-2 d-block mb-1 text-white footer-links' href='mailto:siliconsavannahtechnologies@gmail.com'>siliconsavannahtechnologies@gmail.com</a>
          <div className='social-icons d-flex align-items-center gap-30 mt-4'>
              <a className='text-white footer-links' 
              href='https://web.facebook.com/profile.php?id=100085536918915&mibextid=JRoKGi&_rdc=1&_rdr' target="_blank" rel="noopener noreferrer" >
                <BsFacebook className='fs-4' />
              </a>
              <a className='text-white footer-links' 
              href='https://www.instagram.com/silicon_savannah_technologies?igsh=MW14cGZwczlkNnIwNQ%3D%3D&utm_source=qr' target="_blank" rel="noopener noreferrer">
                <BsInstagram className='fs-4' />
              </a>
              <a className='text-white footer-links' 
              href='https://www.tiktok.com/@silicon_savannah_tech?_t=8jO04KzlM7Z&_r=1' target="_blank" rel="noopener noreferrer">
                <BsTiktok className='fs-4' />
              </a>
              <a className='text-white footer-links' 
              href='https://x.com/SSavannahTec' target="_blank" rel="noopener noreferrer">
                <FaXTwitter className='fs-4' />
              </a>
            </div>
        </div>
        
      </div>
      <div className='col-12 mb-3'>
        <div className='d-flex justify-content-between align-items-center'>
          <div>
            <h4 className='text-white'>Terms</h4>
          </div>
          <div>
            <button className='btn btn-link text-white' type='button' data-bs-toggle='collapse' data-bs-target='#termsLinks' aria-expanded='false' aria-controls='termsLinks'>
            <FaChevronDown />
            </button>
          </div>
        </div>
        
        <div className='collapse' id='termsLinks'>
          <div className='footer-links d-flex flex-column'>
            <Link to='/privacy-policy' className='text-white py-2 mb-1 footer-links' onClick={scrollToTop}>Privacy Policy</Link>
            <Link to='/warranty-refund' className='text-white py-2 mb-1 footer-links' onClick={scrollToTop}>Warranty & Refund Policy</Link>
            <Link to='/delivery-policy' className='text-white py-2 mb-1 footer-links' onClick={scrollToTop}>Delivery Policy</Link>
            <Link to='/terms-service' className='text-white py-2 mb-1 footer-links' onClick={scrollToTop}>Terms Of Service</Link>
          </div>
        </div>
        
      </div>
      <div className='col-12 mb-3' >
       <div className='d-flex justify-content-between align-items-center'>
          <div>
            <h4 className='text-white'>Shop</h4>
          </div>
          <div>
            <button className='btn btn-link text-white' type='button' data-bs-toggle='collapse' data-bs-target='#shopLinks' aria-expanded='false' aria-controls='shopLinks'>
          <FaChevronDown />
          </button>
          </div>
        </div>
        <div className='collapse' id='shopLinks'>
          <div className='footer-links d-flex flex-column'>
            <Link className='text-white py-2 mb-1 footer-links' to='/product?category=Laptops' onClick={scrollToTop}>Laptops</Link>
            <Link className='text-white py-2 mb-1 footer-links' to='/product?category=Apple Products' onClick={scrollToTop}>Apple Products</Link>
            <Link className='text-white py-2 mb-1 footer-links' to='/product?category=Desktops' onClick={scrollToTop}>Desktops</Link>
            <Link className='text-white py-2 mb-1 footer-links' to='/product?category=Printers' onClick={scrollToTop}>Printers</Link>
          </div>
        </div>
        
      </div>
    </div>
  </Container>
</footer>


      <footer className='py-4'>
      <Container>
      <div className='row'>
            <div className='col-12'>
            <p className='text-center mb-0 text-white '>
              &copy; {new Date().getFullYear()}: Powered by{' '} 
              <span style={{textDecoration: 'underline'}} className='text-white'>
                Silicon Savannah Technologies
              </span>
            </p>

            {/* <a className='text-white ' style={{textDecoration: 'underline'}} href='https://wanja-njunge.netlify.app/' target='_blank' rel='noopener noreferrer'>WanjaNjung'e{" "}</a> */}
            
            </div>
          </div>
      </Container>
      </footer>
    </>
  )
}

export default Footer