import React, { useEffect } from 'react';
import Container from '../components/Container';
// import BreadCrumb from '../components/BreadCrumb';
// import Meta from '../components/Meta';
import crossImg from '../assets/images/cross.svg';
import { useDispatch, useSelector } from 'react-redux';
import { addToWishlist, getUserProductWishlist } from '../features/wishlist/wishlistSlice';
import { Link, useNavigate } from 'react-router-dom';

const Wishlist = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let wishlistState = [];
    wishlistState = useSelector(state => state?.wishlist?.wishlist[0]?.wishlist);

    useEffect(() => {
        dispatch(getUserProductWishlist());
      }, [dispatch]);

    

      const removeFromWishlist = (id) => {
        dispatch(addToWishlist(id)).then(() => {
          dispatch(getUserProductWishlist());
        });
      };

    const navigateAndScrollToTop = (itemId) => {
        navigate(`/product/${itemId}`);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
    

  return (
    <>
        {/* <Meta title={'Wishlist'}/>
        <BreadCrumb title="Wishlist"
        /> */}

        <Container class1='wishlist-wrapper home-wrapper-2 py-5 d-lg-block d-none'>
                <div className='row'>
                {(wishlistState === undefined || (wishlistState && wishlistState.length === 0)) && (
                    <div className='text-center fs-3'>
                        <p>No products added to wishlist</p>
                        <Link to='/product' className='button mb-3'>Continue Shopping</Link>
                    </div>
                )}
                {
                    
                    wishlistState && wishlistState?.map((item, index) => {
                        return (
                            <div className='col-3' key={index}>
                        <div className='wishlist-card position-relative'>
                        <img onClick={()=>{removeFromWishlist(item?._id)}}
                        src={crossImg} alt="cross" className='position-absolute cross img-fluid' />
                        <div className='wishlist-card-image bg-white mt-3' onClick={() => navigateAndScrollToTop(item._id)}>
                        <img
                            className='img-fluid mx-auto mt-3' src={item?.images[0]?.url} alt='product' />
                        </div>
                        <div className='py-3 px-3' onClick={() => navigateAndScrollToTop(item._id)}>
                            <h5 className='title'>{item?.title}
                            </h5>
                            <h5 className='title'>{item?.brand}
                            </h5>
                            <h6 className='price'>$ {item?.price}</h6>
                        </div>
                        </div>
                    </div>
                        )
                    })
                }
                    
                    
                </div>
            
        </Container>

        <Container  class1='wishlist-wrapper home-wrapper-2 py-5 d-lg-none'>
                <div className='row'>
                {(wishlistState === undefined || (wishlistState && wishlistState.length === 0)) && (
                    <div className='text-center fs-3'>
                        <p>No products added to wishlist</p>
                        <Link to='/product' className='button mb-3'>Continue Shopping</Link>
                    </div>
                )}

                {
                    
                    wishlistState && wishlistState?.map((item, index) => {
                        return (
                            <div className='col-12' key={index}>
                        <div className='wishlist-card position-relative'>
                        <img onClick={()=>{removeFromWishlist(item?._id)}}
                        src={crossImg} alt="cross" className='position-absolute cross img-fluid' />
                        <div className='wishlist-card-image bg-white mt-3' onClick={() => navigateAndScrollToTop(item._id)}>
                        <img
                            className='img-fluid mx-auto mt-3' src={item?.images[0]?.url} alt='product' />
                        </div>
                        <div className='py-3 px-3' onClick={() => navigateAndScrollToTop(item._id)}>
                            <h5 className='title'>{item?.title}
                            </h5>
                            <h5 className='title'>{item?.brand}
                            </h5>
                            <h6 className='price'>$ {item?.price}</h6>
                        </div>
                        </div>
                    </div>
                        )
                    })
                }
                    
                    
                </div>
            
        </Container>
    </>
  )
}

export default Wishlist

