import axios from "axios";
import { base_url, api } from "../../utils/axiosConfig";

const register = async(data)=>{
    const response = await axios.post(`${base_url}user/register`, data);
    if (response.data) {
        return response.data;
    }
};
const verifyCode = async(data)=>{
  const response = await axios.post(`${base_url}user/verify-user`, { email:data?.email, verificationCode:data?.verificationCode });
  if (response.data) {
    return response.data;
}
};
const login = async(userData)=>{
    const response = await axios.post(`${base_url}user/login`, userData);
    const { email, token, username, wishlist, _id } = response.data;
    if (response.data) {
      localStorage.setItem("customer", JSON.stringify({ email, token, username, _id }));
      localStorage.setItem("wishlist", JSON.stringify(wishlist));
        
        return response.data;
    }
};

// const updateUser = async(userDetail)=>{
    
//     const response = await axios.put(`${base_url}user/edit-user`, userDetail, config);
    
//     if (response.data) {
//         return response.data;
//     }
// };
const updateUser = async (userDetail) => {
    const response = await api.put("user/edit-user", userDetail);
    if (response.data) {
      return response.data;
    }
  };

const forgotPassToken = async(data)=>{
    
    const response = await axios.post(`${base_url}user/forgot-password-token`, data);
    
    if (response.data) {
        return response.data;
    }
};
const resetPass = async(data)=>{
    
    const response = await axios.put(`${base_url}user/reset-password/${data?.token}`, {password: data?.password});
    
    if (response.data) {
        return response.data;
    }
};


export const authService={
    register,
    verifyCode,
    login,
    updateUser,
    forgotPassToken,
    resetPass,
}